import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { IonicModule } from "@ionic/angular";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { SharedModule } from "../shared/shared.module";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";
import { UserProfileApiService } from "./services/user-profile-api.service";
import { UserProfileState } from "./state/user-profile.state";
import { UserProfileRoutingModule } from "./user-profile-routing.module";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        LetModule,
        MatCardModule,
        MatDialogModule,
        NgxsModule.forFeature([UserProfileState]),
        PushModule,
        SharedUiModule,
        UserProfileRoutingModule,
        SharedI18nModule,
        SharedModule,
    ],
    declarations: [UserProfileComponent],
    providers: [
        UserProfileApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdClientLibUserProfile",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
})
export class UserProfileModule {}
