import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";
import { ACKNOWLEDGE_TOAST_ACTION } from "../../report-geolocation.component";

@Component({
    templateUrl: "remote-id-info-toast.component.html",
    styleUrls: ["remote-id-info-toast.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoteIdInfoToastComponent extends Toast {
    constructor(toastrService: ToastrService, toastPackage: ToastPackage) {
        super(toastrService, toastPackage);
    }

    protected acknowledgeAndClose(): void {
        this.toastPackage.triggerAction(ACKNOWLEDGE_TOAST_ACTION);
        this.remove();
    }
}
