import { IconName } from "@dtm-frontend/shared/ui";

export interface MenuItem {
    link: string;
    icon: IconName;
    labelKey: string;
}

export const MENU_ITEMS = {
    home: { link: "/report/user-geolocation", icon: "home-2", labelKey: "uavIdClient.menu.homeMenuItemLabel" },
    myReports: { link: "/my-reports", icon: "file-list", labelKey: "uavIdClient.menu.myReportsMenuItemLabel" },
    myInterventions: { link: "/my-interventions", icon: "file-shield", labelKey: "uavIdClient.menu.myInterventionsMenuItemLabel" },
    help: { link: "/help", icon: "question", labelKey: "uavIdClient.menu.helpMenuItemLabel" },
};
