<ng-container *ngrxLet="statuses$; let statuses">
    <uav-id-client-lib-my-interventions-list-filters
        class="grid"
        [statuses]="statuses"
        [sort]="listSort$ | ngrxPush"
        [initialFilters]="initialFilters"
        (filtersChange)="applyFilters($event)"
        (sortChange)="sortList($event, true)"
    ></uav-id-client-lib-my-interventions-list-filters>
    <uav-id-client-lib-my-interventions-list
        class="grid"
        [reports]="reports$ | ngrxPush"
        [reportsPage]="reportsPage$ | ngrxPush"
        [isProcessing]="isProcessing$ | ngrxPush"
        [hasGetListErrorOccurred]="hasGetListErrorOccurred$ | ngrxPush"
        [sort]="listSort$ | ngrxPush"
        (interventionDialogOpen)="openInterventionNoteDialog($event)"
        (statusChange)="updateRowStatus($event)"
        (pageChange)="changePage($event)"
        (sortChange)="sortList($event)"
        (pageReload)="getMyInterventionsList()"
    ></uav-id-client-lib-my-interventions-list>
</ng-container>
