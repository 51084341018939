import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";

@Component({
    selector: "uav-id-client-lib-report-step-verify-identity",
    templateUrl: "./report-step-verify-identity.component.html",
    styleUrls: ["../report-steps-shared.component.scss", "./report-step-verify-identity.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportStepVerifyIdentityComponent {
    @Output() public next = new EventEmitter<PhoneNumber>();
    @Output() public previous = new EventEmitter<void>();

    public readonly phoneNumberControl = new UntypedFormControl(null, requiredValidForSmsPhoneNumberValidator);

    public submit(): void {
        this.phoneNumberControl.markAsTouched();

        if (this.phoneNumberControl.invalid) {
            return;
        }

        this.next.emit(this.phoneNumberControl.value);
    }
}
