import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule, DatePipe, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDividerModule } from "@angular/material/divider";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LeafletMapModule } from "@dtm-frontend/shared/map/leaflet";
import { LeafletRemoteIdModule } from "@dtm-frontend/shared/map/leaflet-remote-id";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WizardModule } from "@dtm-frontend/shared/ui/wizard";
import { ReportSharedModule } from "@dtm-frontend/uav-identification-shared-lib/report";
import { UavIdentificationSharedModule } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { IonicModule } from "@ionic/angular";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ToastContainerModule } from "ngx-toastr";
import { HelpModule } from "../help/help.module";
import { SharedModule } from "../shared/shared.module";
import { ReportFormWizardComponent } from "./components/report-form-wizard/report-form-wizard.component";
import { ReportStepDetailsComponent } from "./components/report-form-wizard/report-step-details/report-step-details.component";
import { ReportStepHowToReportComponent } from "./components/report-form-wizard/report-step-how-to-report/report-step-how-to-report.component";
import { ReportStepSummaryComponent } from "./components/report-form-wizard/report-step-summary/report-step-summary.component";
import { ReportStepThankYouPageComponent } from "./components/report-form-wizard/report-step-thank-you-page/report-step-thank-you-page.component";
import { ReportStepUavGeolocationComponent } from "./components/report-form-wizard/report-step-uav-geolocation/report-step-uav-geolocation.component";
import { ReportStepVerificationCodeComponent } from "./components/report-form-wizard/report-step-verification-code/report-step-verification-code.component";
import { ReportStepVerifyIdentityComponent } from "./components/report-form-wizard/report-step-verify-identity/report-step-verify-identity.component";
import { ReportStepsNavigationComponent } from "./components/report-form-wizard/report-steps-navigation/report-steps-navigation.component";
import { GeocodingRecentSearchService } from "./components/report-geolocation/geocoding-search-panel/geocoding-recent-search.service";
import { GeocodingResultComponent } from "./components/report-geolocation/geocoding-search-panel/geocoding-result/geocoding-result.component";
import { GeocodingSearchPanelComponent } from "./components/report-geolocation/geocoding-search-panel/geocoding-search-panel.component";
import { FlightsAvailableToastComponent } from "./components/report-geolocation/report-geolocation-toast/flights-available-toast/flights-available-toast.component";
import { FlightsNotAvailableToastComponent } from "./components/report-geolocation/report-geolocation-toast/flights-not-available-toast/flights-not-available-toast.component";
import { RemoteIdInfoToastComponent } from "./components/report-geolocation/report-geolocation-toast/remote-id-info-toast/remote-id-info-toast.component";
import { RemoteIdLocalizationWarningToastComponent } from "./components/report-geolocation/report-geolocation-toast/remote-id-localization-warning-toast/remote-id-localization-warning-toast.component";
import { ReportGeolocationComponent } from "./components/report-geolocation/report-geolocation.component";
import { CanEnterReportFormGuard } from "./guards/can-enter-report-form.guard";
import { ReportRoutingModule } from "./report-routing.module";
import { GeocodingApiService } from "./services/geocoding-api.service";
import { ReportApiService } from "./services/report-api.service";
import { GeocodingState } from "./state/geocoding.state";
import { ReportState } from "./state/report.state";

@NgModule({
    imports: [
        CommonModule,
        HelpModule,
        IonicModule,
        LayoutModule,
        LeafletMapModule,
        LeafletRemoteIdModule,
        LetModule,
        MatCheckboxModule,
        MatDividerModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        NgxsModule.forFeature([ReportState, GeocodingState]),
        NgOptimizedImage,
        PushModule,
        ReactiveFormsModule,
        ReportSharedModule,
        ReportRoutingModule,
        SharedI18nModule,
        SharedModule,
        SharedUiModule,
        ToastContainerModule,
        WizardModule,
        UavIdentificationSharedModule,
    ],
    declarations: [
        FlightsAvailableToastComponent,
        FlightsNotAvailableToastComponent,
        GeocodingResultComponent,
        GeocodingSearchPanelComponent,
        RemoteIdInfoToastComponent,
        RemoteIdLocalizationWarningToastComponent,
        ReportFormWizardComponent,
        ReportGeolocationComponent,
        ReportStepDetailsComponent,
        ReportStepHowToReportComponent,
        ReportStepsNavigationComponent,
        ReportStepSummaryComponent,
        ReportStepThankYouPageComponent,
        ReportStepUavGeolocationComponent,
        ReportStepVerificationCodeComponent,
        ReportStepVerifyIdentityComponent,
    ],
    providers: [
        CanEnterReportFormGuard,
        DatePipe,
        GeocodingApiService,
        GeocodingRecentSearchService,
        ReportApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdClientLibReport",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [LeafletMapModule],
})
export class ReportModule {}
