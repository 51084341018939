import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmAuthGuard } from "@dtm-frontend/shared/auth";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { DeleteAccountComponent } from "./components/delete-account/delete-account.component";
import { EmailVerificationComponent } from "./components/email-verification/email-verification.component";
import { LoginPageComponent } from "./components/login-page/login-page.component";
import { PhoneVerificationComponent } from "./components/phone-verification/phone-verification.component";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { IsCivilianGuard } from "./guards/is-civilian.guard";
import { IsNotLoggedInGuard } from "./guards/is-not-logged-in.guard";
import { RegistrationVerificationGuard } from "./guards/registration-verification.guard";

const routes: Routes = [
    {
        path: "registration",
        component: RegistrationComponent,
        canActivate: [IsNotLoggedInGuard],
    },
    {
        path: "phone-verification",
        component: PhoneVerificationComponent,
        canActivate: [RegistrationVerificationGuard],
    },
    {
        path: "email-verification",
        component: EmailVerificationComponent,
        canActivate: [RegistrationVerificationGuard],
    },
    {
        path: "login",
        component: LoginPageComponent,
        canActivate: [IsNotLoggedInGuard],
    },
    {
        path: "reset-password",
        component: ResetPasswordComponent,
        canActivate: [IsNotLoggedInGuard],
    },
    {
        path: "change-password",
        component: ChangePasswordComponent,
        canActivate: [DtmAuthGuard],
    },
    {
        path: "delete-account",
        component: DeleteAccountComponent,
        canActivate: [DtmAuthGuard, IsCivilianGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthorizationRoutingModule {}
