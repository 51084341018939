import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Logger } from "@dtm-frontend/shared/utils";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Store } from "@ngxs/store";
import { combineLatestWith, map, Observable } from "rxjs";
import { ReportState } from "../state/report.state";

@Injectable()
export class CanEnterReportFormGuard {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public canActivate(): Observable<boolean> {
        return this.store.select(ReportState.userPosition).pipe(
            combineLatestWith(this.store.select(AuthState.roles).pipe(map((roles) => roles?.includes(UavIdRole.Admin)))),
            map(([userPosition, isAdmin]) => {
                if (!userPosition || isAdmin) {
                    this.router.navigateByUrl("/report/user-geolocation").catch(Logger.captureException);

                    return false;
                }

                return true;
            })
        );
    }
}
