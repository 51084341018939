<div toastContainer class="map-info-toast-container" aria-live="polite"></div>
<dtm-map-leaflet-map *ngrxLet="{ userPosition: userPosition$, isAirspaceLayerEnabled: isAirspaceLayerEnabled$ } as vm">
    <dtm-map-leaflet-user-position
        [userPosition]="vm.userPosition"
        [arePositionChangesEnabled]="true"
        (userPositionUpdate)="onUserPositionUpdated($event)"
        (geoLocationDeny)="onGeoLocationDenied()"
    ></dtm-map-leaflet-user-position>

    <div topRightControls class="top-right-controls leaflet-button-group">
        <button
            *ngIf="vm.userPosition"
            type="button"
            class="leaflet-button"
            [matTooltip]="'uavIdClientLibReport.reportGeolocation.refreshMapButtonLabel' | transloco"
            (click)="refreshData()"
        >
            <dtm-ui-icon name="refresh"></dtm-ui-icon>
        </button>
        <button
            type="button"
            class="leaflet-button"
            routerLink="/help"
            [matTooltip]="'uavIdClientLibReport.reportGeolocation.helpButtonLabel' | transloco"
        >
            <dtm-ui-icon name="question"></dtm-ui-icon>
        </button>
    </div>

    <div bottomRightControls>
        <button *ngIf="vm.userPosition" type="button" class="button-primary report-button ion-hide-xl-up" routerLink="/report/form">
            {{ "uavIdClientLibReport.reportGeolocation.reportButtonLabel" | transloco }}
        </button>
    </div>

    <dtm-map-leaflet-map-layer
        *ngIf="isPoliceHeadquartersLayerEnabled"
        [layerConfig]="POLICE_HEADQUARTERS_LAYER_CONFIG"
    ></dtm-map-leaflet-map-layer>
    <dtm-map-leaflet-map-layer *ngIf="vm.isAirspaceLayerEnabled" [layerConfig]="AIRSPACE_LAYER_CONFIG"></dtm-map-leaflet-map-layer>
    <uav-id-shared-lib-leaflet-flights-layer
        [flights]="flights$ | ngrxPush"
        [hasExtendedDetails]="hasExtendedFlightDetails$ | ngrxPush"
    ></uav-id-shared-lib-leaflet-flights-layer>
    <dtm-map-leaflet-remote-id-layer dtmMapLeafletRemoteIdData shouldRemoveObsoleteRecords></dtm-map-leaflet-remote-id-layer>
</dtm-map-leaflet-map>

<uav-id-client-lib-geocoding-search-panel *ngIf="isGeoLocationDenied$ | ngrxPush"></uav-id-client-lib-geocoding-search-panel>

<div *ngIf="(areFlightsProcessing$ | ngrxPush) || (isPositionProcessing$ | ngrxPush)" @fade class="spinner-container">
    <mat-spinner diameter="60" color="accent"></mat-spinner>
</div>
