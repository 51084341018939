import { Injectable } from "@angular/core";
import { Action, Actions, ofActionDispatched, Selector, State, StateContext } from "@ngxs/store";
import { catchError, EMPTY, finalize, takeUntil, tap } from "rxjs";
import { GeocodingResult } from "../services/geocoding-api.converters";
import { GeocodingApiService } from "../services/geocoding-api.service";
import { GeocodingActions } from "./geocoding.actions";

export interface GeocodingError {
    type: GeocodingErrorType;
}

export enum GeocodingErrorType {
    Unknown = "GeocodingErrorUnknown",
}
export interface GeocodingStateModel {
    geocodingError: GeocodingError | undefined;
    result: GeocodingResult[] | undefined;
    isProcessing: boolean;
}

const defaultState: GeocodingStateModel = {
    geocodingError: undefined,
    result: undefined,
    isProcessing: false,
};

@State<GeocodingStateModel>({
    name: "geocoding",
    defaults: defaultState,
})
@Injectable()
export class GeocodingState {
    @Selector()
    public static geocodingError(state: GeocodingStateModel): GeocodingError | undefined {
        return state.geocodingError;
    }

    @Selector()
    public static geocodingResult(state: GeocodingStateModel): GeocodingResult[] | undefined {
        return state.result;
    }

    @Selector()
    public static isProcessing(state: GeocodingStateModel): boolean {
        return state.isProcessing;
    }

    constructor(private readonly actions$: Actions, private readonly geocodingApi: GeocodingApiService) {}

    @Action(GeocodingActions.GetGeocodes)
    public getGeocodes(context: StateContext<GeocodingStateModel>, action: GeocodingActions.GetGeocodes) {
        context.patchState({ isProcessing: true });

        return this.geocodingApi.getGeocodes(action.query).pipe(
            tap((result) => context.patchState({ result, geocodingError: undefined })),
            catchError((error) => {
                context.patchState({ geocodingError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false })),
            takeUntil(this.actions$.pipe(ofActionDispatched(GeocodingActions.ClearResults)))
        );
    }

    @Action(GeocodingActions.ClearResults)
    public clearResults(context: StateContext<GeocodingStateModel>) {
        context.patchState({ result: undefined });
    }
}
