<div>
    <section class="description">
        <ng-content></ng-content>
    </section>
    <dtm-ui-input-field (keyup.enter)="submit()">
        <label>{{ "uavIdClientLibShared.codeVerificationTemplate.verificationCodeLabel" | transloco }}</label>
        <input matInput [formControl]="verificationCodeControl" [mask]="VERIFICATION_CODE_MASK" [showMaskTyped]="true" />
        <div class="field-error" *dtmUiFieldHasError="verificationCodeControl; name: ['required', 'requiredTouched']">
            {{ "uavIdClientLibShared.codeVerificationTemplate.fieldRequiredError" | transloco }}
        </div>
    </dtm-ui-input-field>
</div>

<div class="buttons">
    <button
        *ngrxLet="isResendActionDisabled$ as isResendActionDisabled"
        class="button-secondary"
        type="button"
        (click)="resendVerificationCode()"
        [disabled]="isResendActionDisabled"
    >
        {{ "uavIdClientLibShared.codeVerificationTemplate.sendAgainButtonLabel" | transloco }}
        <span *ngIf="isResendActionDisabled"> - {{ timeLeft$ | ngrxPush | invoke : transformTimeLeft }}</span>
    </button>

    <button class="button-primary" type="submit" [disabled]="verificationCodeControl.invalid" (click)="submit()">
        {{ "uavIdClientLibShared.codeVerificationTemplate.verifyButtonLabel" | transloco }}
    </button>
</div>
