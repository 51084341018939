import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface CardTemplateComponentState {
    title: string | undefined;
    isNavigationBackEnabled: boolean;
    isCustomNavigation: boolean;
}

@Component({
    selector: "uav-id-client-lib-card-template",
    templateUrl: "./card-template.component.html",
    styleUrls: ["./card-template.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class CardTemplateComponent {
    @Input() public set title(value: string) {
        this.localStore.patchState({ title: value });
    }
    @Input() public set isNavigationBackEnabled(value: boolean) {
        this.localStore.patchState({ isNavigationBackEnabled: value });
    }
    @Input() public set isCustomNavigation(value: boolean) {
        this.localStore.patchState({ isCustomNavigation: value });
    }

    @Output() public navigateBack = new EventEmitter();

    public title$ = this.localStore.selectByKey("title");
    public isNavigationBackEnabled$ = this.localStore.selectByKey("isNavigationBackEnabled");

    constructor(private readonly localStore: LocalComponentStore<CardTemplateComponentState>, private readonly location: Location) {
        this.localStore.setState({
            title: undefined,
            isNavigationBackEnabled: true,
            isCustomNavigation: false,
        });
    }

    public goBack(): void {
        if (!this.localStore.selectSnapshotByKey("isCustomNavigation")) {
            this.location.back();

            return;
        }

        this.navigateBack.emit();
    }
}
