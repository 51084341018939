<section class="header">
    <button type="button" class="button-secondary" routerLink="..">
        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
        {{ "uavIdClientLibMyReports.myReportPreview.goBackToListButtonLabel" | transloco }}
    </button>
</section>

<ng-container
    *ngrxLet="{
        reportSummary: reportSummary$,
        hasGetReportSummaryErrorOccurred: hasGetReportSummaryErrorOccurred$,
        hasExtendedDetails: hasExtendedDetails$
    } as vm"
>
    <ng-container *ngIf="!vm.hasGetReportSummaryErrorOccurred; else errorMessageTemplate">
        <section *ngIf="vm.reportSummary?.report as report" class="content">
            <div class="data-panel">
                <div class="data-panel-content">
                    <section class="data-panel-header">
                        <h2>{{ "uavIdClientLibMyReports.myReportPreview.reportHeaderLabel" | transloco : { number: report.number } }}</h2>
                    </section>

                    <uav-id-shared-lib-report-preview-details [report]="report"></uav-id-shared-lib-report-preview-details>
                </div>
            </div>

            <uav-id-shared-lib-report-preview-map
                [report]="report"
                [flights]="vm.reportSummary?.flights"
                [remoteIds]="vm.reportSummary?.remoteIds"
                [hasExtendedDetails]="vm.hasExtendedDetails"
                [zonesLayerConfig]="REPORT_GEO_ZONES_LAYER_CONFIG"
                [isZonesInfoProcessing]="isSelectedReportGeoZonesInfoLoading$ | ngrxPush"
                [zonesInfo]="selectedReportGeoZonesInfo$ | ngrxPush"
                [zonesError]="selectedReportGeoZonesInfoError$ | ngrxPush"
                (zonesSelect)="getSelectedZonesInfo($event)"
                (zoneDetailsOpen)="openZoneDetailsDialog($event)"
            ></uav-id-shared-lib-report-preview-map>
        </section>
    </ng-container>
</ng-container>

<ng-template #errorMessageTemplate>
    <dtm-ui-error [errorMessage]="'uavIdClientLibMyReports.myReportPreview.unknownError' | transloco"></dtm-ui-error>
</ng-template>
