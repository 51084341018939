<ion-menu #ionMenu side="start" content-id="main" [disabled]="isDesktop$ | ngrxPush" [swipeGesture]="false">
    <ion-header>
        <ion-toolbar>
            <div class="logo">
                <a routerLink="/" (click)="ionMenu.close()">
                    <img src="assets/images/logo.svg" alt="logo" />
                </a>
            </div>
        </ion-toolbar>
    </ion-header>

    <ion-content>
        <ion-list>
            <uav-id-client-menu-items
                class="mobile-menu"
                [areIconsDisplayed]="true"
                (itemClicked)="ionMenu.close()"
            ></uav-id-client-menu-items>

            <dtm-ui-webpage-footer
                *ngIf="isSmartphoneOrTablet$ | ngrxPush"
                [activeLanguage]="activeLanguage$ | ngrxPush"
                [isCookiesInfoAvailable]="!isMobileApp"
                (cookiesInfoSelect)="cookiesInfoOpen.emit()"
            ></dtm-ui-webpage-footer>
        </ion-list>
    </ion-content>
</ion-menu>
