<div class="form-container">
    <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
        <div class="form-card">
            <h2 class="header">{{ "uavIdClientLibAuth.changePassword.header" | transloco }}</h2>

            <uav-id-client-lib-update-password
                (passwordUpdate)="updatePassword($event)"
                (codeResend)="resendVerificationCode()"
            ></uav-id-client-lib-update-password>

            <button type="button" class="button-secondary back-button" (click)="goToUserProfilePage()">
                {{ "uavIdClientLibAuth.changePassword.goBackToUserProfilePageButtonLabel" | transloco }}
            </button>
        </div>
    </dtm-ui-loader-container>
</div>
