import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { ReportType } from "../../../models/report.model";

interface ReportStepHowToReportComponentState {
    canIntervene: boolean;
    isLoggedIn: boolean;
}

@Component({
    selector: "uav-id-client-lib-report-step-how-to-report",
    templateUrl: "./report-step-how-to-report.component.html",
    styleUrls: ["../report-steps-shared.component.scss", "./report-step-how-to-report.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportStepHowToReportComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;

    @Input() public set canIntervene(value: BooleanInput) {
        this.localStore.patchState({ canIntervene: coerceBooleanProperty(value) });
    }

    @Input() public set isLoggedIn(value: BooleanInput) {
        this.localStore.patchState({ isLoggedIn: coerceBooleanProperty(value) });
    }

    @Output() protected readonly next = new EventEmitter<ReportType>();

    protected readonly ReportType = ReportType;

    protected readonly isNotLoggedIn$ = this.localStore.selectByKey("isLoggedIn").pipe(map((isLoggedIn) => !isLoggedIn));
    protected readonly canIntervene$ = this.localStore.selectByKey("canIntervene");

    protected readonly areTermsOfUseAcceptedControl = new FormControl<boolean>(false, [Validators.requiredTrue]);

    constructor(
        private readonly localStore: LocalComponentStore<ReportStepHowToReportComponentState>,
        private readonly location: Location,
        @Inject(TERMS_OF_USE_URL) protected readonly termsOfUseUrl: string
    ) {
        this.localStore.setState({
            canIntervene: false,
            isLoggedIn: false,
        });
    }

    protected goBack(): void {
        this.location.back();
    }

    protected goToNextStep(): void {
        if (!this.localStore.selectSnapshotByKey("isLoggedIn") && this.areTermsOfUseAcceptedControl.invalid) {
            this.areTermsOfUseAcceptedControl.markAsTouched();
            this.invalidFormScrollable?.scrollToFirstInvalidField();

            return;
        }

        this.next.emit(ReportType.Standard);
    }
}
