import { OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";

export interface RegistrationFormData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: PhoneNumber;
    password: string;
    isDataConfirmed: boolean;
    isOfficerAccount: boolean;
    officerUnit: OfficerUnitEntity;
}

export interface AuthorizationData {
    registrationId: string;
    phoneNumber: PhoneNumber;
    email: string;
    password: string;
    isOfficerAccount: boolean;
}

export interface CodeVerificationError {
    type: CodeVerificationErrorType;
}

export enum CodeVerificationErrorType {
    Unknown = "Unknown",
    CodeResendFailed = "CodeResendFailed",
    CodeExpired = "CodeExpired",
    InvalidCode = "InvalidCode",
}

export interface RegistrationError {
    type: RegistrationErrorType;
}

export enum RegistrationErrorType {
    Unknown = "RegistrationErrorUnknown",
    EmailAlreadyExists = "RegistrationErrorEmailAlreadyExists",
    EmailInvalid = "RegistrationErrorEmailInvalid",
    PhoneNumberAlreadyExists = "RegistrationErrorPhoneNumberAlreadyExists",
    PhoneNumberInvalid = "RegistrationErrorPhoneNumberInvalid",
}

interface PhoneNumber {
    countryCode: string;
    number: string;
}

export interface UpdatePasswordData {
    code: string;
    newPassword: string;
}

export interface ResetPasswordError {
    type: ResetPasswordErrorType;
}

export enum ResetPasswordErrorType {
    AccountNotFound = "AccountNotFound",
    Unknown = "Unknown",
}

export interface ChangePasswordError {
    type: ChangePasswordErrorType;
}

export enum ChangePasswordErrorType {
    Unknown = "Unknown",
}

export interface AccountDeletionError {
    type: AccountDeletionErrorType;
}

export enum AccountDeletionErrorType {
    Unknown = "Unknown",
}
