import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { tap } from "rxjs";
import { PageService } from "../../../shared/services/page.service";
import { FaqUrl } from "../../models/help.models";

interface HelpContainerComponentState {
    previousPage: string;
}

const MOBILE_DEVICE_SIZES: DeviceSize[] = [DeviceSize.Smartphone, DeviceSize.SmartphoneWide, DeviceSize.Tablet];
const FIRST_SUBPAGE_URL = `/help/${FaqUrl.WhenToReport}`;
const FAQ_SECTION_HEADERS: Record<FaqUrl, string> = {
    [FaqUrl.WhenToReport]: "uavIdClientLibHelp.whenToReport.header",
    [FaqUrl.HowToReport]: "uavIdClientLibHelp.howToReport.header",
    [FaqUrl.CallEmergencyNumber]: "uavIdClientLibHelp.callEmergencyNumber.header",
    [FaqUrl.ApplicationPurpose]: "uavIdClientLibHelp.applicationPurpose.header",
    [FaqUrl.DataSource]: "uavIdClientLibHelp.dataSource.header",
    [FaqUrl.RemoteIdOrigin]: "uavIdClientLibHelp.remoteIdOrigin.header",
    [FaqUrl.RemoteIdDataMissing]: "uavIdClientLibHelp.remoteIdDataMissing.header",
    [FaqUrl.ReportProcess]: "uavIdClientLibHelp.reportProcess.header",
    [FaqUrl.ReportWithoutCall]: "uavIdClientLibHelp.reportWithoutCall.header",
    [FaqUrl.ReportStatuses]: "uavIdClientLibHelp.reportStatuses.header",
    [FaqUrl.PhoneNumberVerification]: "uavIdClientLibHelp.phoneNumberVerification.header",
};

@Component({
    selector: "uav-id-client-lib-help-container",
    templateUrl: "help-container.component.html",
    styleUrls: ["../help.scss", "help-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HelpContainerComponent {
    protected readonly faqUrls = Object.values(FaqUrl);

    protected readonly isMobile$ = this.deviceSizeService.getSizeObservable(...MOBILE_DEVICE_SIZES).pipe(
        tap((isMobile) => {
            if (!isMobile) {
                this.navigateToFirstSubPage();
            }
        })
    );

    constructor(
        private readonly deviceSizeService: DeviceSizeService,
        private readonly localStore: LocalComponentStore<HelpContainerComponentState>,
        private readonly location: Location,
        private readonly pageService: PageService,
        private readonly router: Router
    ) {
        this.localStore.setState({
            previousPage: "/",
        });
    }

    public ionViewWillEnter(): void {
        this.localStore.patchState({ previousPage: this.pageService.getPreviousPage() });

        if (!this.isMobile()) {
            this.navigateToFirstSubPage();
        }
    }

    protected goBack(): void {
        if (this.isMobile()) {
            this.location.back();

            return;
        }

        this.router.navigateByUrl(this.localStore.selectSnapshotByKey("previousPage"));
    }

    protected getFaqSectionHeader(faqUrl: FaqUrl): string {
        return FAQ_SECTION_HEADERS[faqUrl];
    }

    private isMobile(): boolean {
        return this.deviceSizeService.isSize(...MOBILE_DEVICE_SIZES);
    }

    private navigateToFirstSubPage(): void {
        if (this.pageService.getCurrentPage() !== "/help") {
            return;
        }

        this.router.navigateByUrl(FIRST_SUBPAGE_URL);
    }
}
