export namespace FlightActions {
    export class StartFlightsUpdatesWatch {
        public static readonly type = "[Flight] Start flights updates watch";
    }

    export class StopFlightsUpdatesWatch {
        public static readonly type = "[Flight] Stop flights updates watch";
    }

    export class GetFlights {
        public static readonly type = "[Flight] Get flights";
        constructor(public latitude: number, public longitude: number, public date?: Date) {}
    }
}
