import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Store } from "@ngxs/store";
import { map, Observable } from "rxjs";

const USER_PROFILE_URL = "/user-profile";

@Injectable()
export class IsCivilianGuard {
    constructor(private readonly router: Router, private readonly store: Store) {}

    public canActivate(): Observable<boolean> {
        return this.store.select(AuthState.roles).pipe(
            map((roles) => {
                if (!roles?.some((role) => role === UavIdRole.Civilian)) {
                    this.router.navigateByUrl(USER_PROFILE_URL);
                }

                return true;
            })
        );
    }
}
