import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Sort } from "@angular/material/sort";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { PAGE_NUMBER_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import {
    InvolvementType,
    ReportInterventionNoteDialogService,
    ReportList,
    ReportListFilters,
    ReportManagementActions,
    ReportManagementState,
    ReportStatus,
} from "@dtm-frontend/uav-identification-shared-lib/report";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { distinctUntilChanged, firstValueFrom, map, switchMap, tap } from "rxjs";

interface MyReportsListContainerComponentState {
    statuses: ReportStatus[];
}

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-my-reports-list-container",
    templateUrl: "./my-reports-list-container.component.html",
    styleUrls: ["./my-reports-list-container.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MyReportsListContainerComponent {
    protected readonly reports$ = this.store.select(ReportManagementState.reports);
    protected readonly reportsPage$ = this.store.select(ReportManagementState.reportsPage);
    protected readonly isProcessing$ = this.store.select(ReportManagementState.isProcessing);
    protected readonly hasGetListErrorOccurred$ = this.store.select(ReportManagementState.hasGetListErrorOccurred);
    protected readonly statuses$ = this.localStore.selectByKey("statuses");
    protected readonly listSort$ = this.route.queryParams.pipe(
        map(({ active, direction }) => ({ active, direction })),
        distinctUntilChanged()
    );

    protected initialFilters: ReportListFilters;

    constructor(
        private readonly interventionNoteDialogService: ReportInterventionNoteDialogService,
        private readonly localStore: LocalComponentStore<MyReportsListContainerComponentState>,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly store: Store
    ) {
        const { text = null, statuses = null, createdDateFrom = null, createdDateTo = null } = this.route.snapshot.queryParams ?? {};

        this.initialFilters = {
            text,
            statuses: statuses?.split(",") ?? [],
            createdDateFrom,
            createdDateTo,
        };

        this.localStore.setState({
            statuses: Object.values(ReportStatus) as ReportStatus[],
        });
        this.updateReportListOnQueryParamsChange();
    }

    protected applyFilters(filters: Partial<ReportListFilters>): void {
        const { text, statuses, createdDateFrom, createdDateTo } = filters ?? {};

        const params = {
            text: text ? text : null,
            statuses: statuses?.length ? statuses.join(",") : null,
            createdDateFrom: createdDateFrom ? this.convertToISOString(createdDateFrom) : null,
            createdDateTo: createdDateTo ? this.convertToISOString(createdDateTo) : null,
            [PAGE_NUMBER_QUERY_PARAM]: 0,
        };

        this.updateQueryParams(params);
    }

    protected getMyReportsList(): Promise<ReportList> {
        return firstValueFrom(
            this.route.queryParams.pipe(
                switchMap((queryParams) =>
                    this.store.dispatch(new ReportManagementActions.GetReports(InvolvementType.Creator, queryParams))
                ),
                untilDestroyed(this)
            )
        );
    }

    protected changePage(event: PageEvent): void {
        this.updateQueryParams({ [PAGE_NUMBER_QUERY_PARAM]: event?.pageIndex, [PAGE_SIZE_QUERY_PARAM]: event?.pageSize });
    }

    protected sortList(sort: Sort, resetPage = false): void {
        const active = sort.direction ? sort.active : null;
        const direction = sort.direction ? sort.direction : null;

        this.updateQueryParams({ active, direction, ...(resetPage && { [PAGE_NUMBER_QUERY_PARAM]: 0 }) });
    }

    private updateQueryParams(queryParams: Params): void {
        this.router
            .navigate(["."], {
                relativeTo: this.route,
                queryParams,
                queryParamsHandling: "merge",
            })
            .catch(console.error);
    }

    private updateReportListOnQueryParamsChange(): void {
        this.route.queryParams
            .pipe(
                distinctUntilChanged(),
                tap(() => this.getMyReportsList()),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private convertToISOString(value: Date | string): string {
        return value instanceof Date ? value.toISOString() : value;
    }
}
