import { ModuleWithProviders, NgModule } from "@angular/core";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { NgxsModule } from "@ngxs/store";
import { FlightApiService } from "./services/flight-api.service";
import { FlightState } from "./state/flight.state";

@NgModule({
    imports: [NgxsModule.forFeature([FlightState])],
    providers: [FlightApiService, WebsocketService],
})
export class FlightModule {
    public static forTest(): ModuleWithProviders<FlightModule> {
        return {
            ngModule: FlightModule,
            providers: [
                { provide: FlightApiService, useValue: null },
                { provide: WebsocketService, useValue: null },
            ],
        };
    }
}
