import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { UserProfileComponent } from "./components/user-profile/user-profile.component";

const routes: Routes = [
    {
        path: "user-profile",
        component: UserProfileComponent,
        canActivate: [DtmRoleGuard],
        data: {
            roles: [UavIdRole.Admin, UavIdRole.Civilian, UavIdRole.Officer, UavIdRole.DutyOfficer],
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UserProfileRoutingModule {}
