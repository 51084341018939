export namespace GeocodingActions {
    export class GetGeocodes {
        public static readonly type = "[Geocoding] Get geocodes";
        constructor(public query: string) {}
    }

    export class ClearResults {
        public static readonly type = "[Geocoding] Clear results";
        constructor() {}
    }
}
