import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { RemoteId } from "remote-id";

export interface ReportError {
    type: ReportErrorType;
}

export enum ReportErrorType {
    Unknown = "ReportErrorUnknown",
    CannotSubmitReport = "ReportErrorCannotSubmitReport",
    ReportAbroad = "ReportErrorReportAbroad",
    PhoneNumberInvalid = "ReportErrorPhoneNumberInvalid",
    PhoneNumberAlreadyUsed = "ReportErrorPhoneNumberAlreadyUsed",
    VerificationCodeExpired = "ReportErrorVerificationCodeExpired",
    VerificationCodeInvalid = "ReportErrorVerificationCodeInvalid",
}

export enum ReportFormWizardSteps {
    HowToReport = "HowToReport",
    VerifyIdentity = "VerifyIdentity",
    VerificationCode = "VerificationCode",
    UavGeolocation = "UavGeolocation",
    Details = "Details",
    Summary = "Summary",
    ThankYouPage = "ThankYouPage",
}

export enum ReportType {
    Standard = "Standard",
    Intervention = "Intervention",
}

export interface ReportFormData {
    uav: {
        height: string;
        latitude: number;
        longitude: number;
    };
    pilot: {
        isVisible: boolean;
        appearance: string;
    };
    user: {
        latitude: number;
        longitude: number;
        phoneNumber: PhoneNumber | undefined;
    };
    reasons: string[];
    otherReasons: string;
    reportStartedAt: Date;
    interventionNote: string;
    isIntervention: boolean;
    remoteIds: Array<Partial<RemoteId.Data>>;
}

export interface ReportIdentity {
    verificationId: string;
    phoneNumber: PhoneNumber;
    isVerified: boolean;
}
