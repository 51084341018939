import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import {
    ButtonTheme,
    ConfirmationDialogComponent,
    DataChangeActionType,
    DataUpdateAction,
    DialogService,
    PhoneNumber,
} from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UavIdRole, UserType } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs";
import { UserProfileError, UserProfileErrorType } from "../../models/user-profile.models";
import { UserProfileActions } from "../../state/user-profile.actions";
import { UserProfileState } from "../../state/user-profile.state";

interface UserProfileComponentState {
    isEmailUpdateRequested: boolean;
    hasEmailConflictError: boolean;
    newEmail: string | undefined;
    isPhoneNumberUpdateRequested: boolean;
    hasPhoneNumberConflictError: boolean;
    newPhoneNumber: PhoneNumber | undefined;
}

const CHANGE_PASSWORD_URL = "/change-password";
const DELETE_ACCOUNT_URL = "/delete-account";

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-user-profile",
    templateUrl: "./user-profile.component.html",
    styleUrls: ["./user-profile.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UserProfileComponent {
    protected readonly UserType = UserType;

    protected readonly hasCivilianRole$ = this.store
        .select(AuthState.roles)
        .pipe(map((roles) => roles?.some((role) => role === UavIdRole.Civilian)));
    protected readonly isProcessing$ = this.store.select(UserProfileState.isProcessing);
    protected readonly userData$ = this.store.select(UserProfileState.userData);
    protected readonly userDataError$ = this.store.select(UserProfileState.userDataError);
    protected readonly isEmailUpdateRequested$ = this.localStore.selectByKey("isEmailUpdateRequested");
    protected readonly hasEmailConflictError$ = this.localStore.selectByKey("hasEmailConflictError");
    protected readonly isPhoneNumberUpdateRequested$ = this.localStore.selectByKey("isPhoneNumberUpdateRequested");
    protected readonly hasPhoneNumberConflictError$ = this.localStore.selectByKey("hasPhoneNumberConflictError");

    constructor(
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<UserProfileComponentState>,
        private readonly router: Router,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        this.localStore.setState({
            isEmailUpdateRequested: false,
            hasEmailConflictError: false,
            newEmail: undefined,
            isPhoneNumberUpdateRequested: false,
            hasPhoneNumberConflictError: false,
            newPhoneNumber: undefined,
        });
    }

    public ionViewWillEnter(): void {
        this.reload();
    }

    protected updateUserProfileData({ payload, actionType }: DataUpdateAction): void {
        switch (actionType) {
            case DataChangeActionType.RequestEmailChange:
                this.requestEmailUpdate(payload as string);
                break;
            case DataChangeActionType.RequestPhoneNumberChange:
                this.requestPhoneNumberUpdate(payload as PhoneNumber);
                break;
            case DataChangeActionType.SaveNewEmailAddress:
                this.updateEmail(payload as string);
                break;
            case DataChangeActionType.SaveNewPhoneNumber:
                this.updatePhoneNumber(payload as string);
                break;
        }
    }

    protected changePassword(): void {
        this.router.navigateByUrl(CHANGE_PASSWORD_URL);
    }

    protected deleteAccount(): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("uavIdClientLibUserProfile.deleteAccountDialog.titleText"),
                    confirmationText: this.translocoService.translate("uavIdClientLibUserProfile.deleteAccountDialog.confirmationText"),
                    confirmButtonLabel: this.translocoService.translate("uavIdClientLibUserProfile.deleteAccountDialog.confirmButtonLabel"),
                    declineButtonLabel: this.translocoService.translate("uavIdClientLibUserProfile.deleteAccountDialog.declineButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.router.navigateByUrl(DELETE_ACCOUNT_URL);
            });
    }

    protected reload(): void {
        this.store.dispatch(new UserProfileActions.GetUserData(this.store.selectSnapshot(AuthState.userId)));
    }

    protected clearEmailLocalState(): void {
        this.localStore.patchState({ newEmail: undefined, isEmailUpdateRequested: false, hasEmailConflictError: false });
    }

    protected clearPhoneNumberLocalState(): void {
        this.localStore.patchState({ newPhoneNumber: undefined, isPhoneNumberUpdateRequested: false, hasPhoneNumberConflictError: false });
    }

    private requestEmailUpdate(email: string): void {
        this.localStore.patchState({ hasEmailConflictError: false });

        this.store
            .dispatch(new UserProfileActions.RequestEmailUpdate(email))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.requestEmailUpdateError);

                if (error) {
                    if (error.type === UserProfileErrorType.AlreadyExists) {
                        this.localStore.patchState({ hasEmailConflictError: true });

                        return;
                    }

                    this.displayErrorToast();

                    return;
                }

                this.localStore.patchState({ isEmailUpdateRequested: true, newEmail: email });
            });
    }

    private requestPhoneNumberUpdate(phoneNumber: PhoneNumber): void {
        this.localStore.patchState({ hasPhoneNumberConflictError: false });

        this.store
            .dispatch(new UserProfileActions.RequestPhoneNumberUpdate(phoneNumber))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.requestPhoneNumberUpdateError);

                if (error) {
                    if (error.type === UserProfileErrorType.AlreadyExists) {
                        this.localStore.patchState({ hasPhoneNumberConflictError: true });

                        return;
                    }

                    this.displayErrorToast();

                    return;
                }

                this.localStore.patchState({ isPhoneNumberUpdateRequested: true, newPhoneNumber: phoneNumber });
            });
    }

    private updateEmail(verificationCode: string): void {
        const newEmail = this.localStore.selectSnapshotByKey("newEmail");

        if (!newEmail) {
            return;
        }

        this.store
            .dispatch(new UserProfileActions.UpdateEmail(newEmail, verificationCode))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.updateEmailError);

                if (error) {
                    this.handleVerificationCodeError(error);

                    return;
                }

                this.displaySuccessToast("uavIdClientLibUserProfile.contact.updateEmailSuccessMessage");
                this.clearEmailLocalState();
            });
    }

    private updatePhoneNumber(verificationCode: string): void {
        const newPhoneNumber = this.localStore.selectSnapshotByKey("newPhoneNumber");

        if (!newPhoneNumber) {
            return;
        }

        this.store
            .dispatch(new UserProfileActions.UpdatePhoneNumber(newPhoneNumber, verificationCode))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(UserProfileState.updatePhoneNumberError);

                if (error) {
                    this.handleVerificationCodeError(error);

                    return;
                }

                this.displaySuccessToast("uavIdClientLibUserProfile.contact.updatePhoneNumberSuccessMessage");
                this.clearPhoneNumberLocalState();
            });
    }

    private displayErrorToast(messageKey?: string): void {
        this.toastrService.error(this.translocoService.translate(messageKey ?? "uavIdClientLibUserProfile.contact.genericErrorMessage"));
    }

    private displaySuccessToast(messageKey: string): void {
        this.toastrService.success(this.translocoService.translate(messageKey));
    }

    private handleVerificationCodeError(error: UserProfileError): void {
        if (error.type === UserProfileErrorType.InvalidCode) {
            this.displayErrorToast("uavIdClientLibUserProfile.contact.verificationCodeInvalidErrorMessage");

            return;
        }

        if (error.type === UserProfileErrorType.ExpiredCode) {
            this.displayErrorToast("uavIdClientLibUserProfile.contact.verificationCodeExpiredErrorMessage");

            return;
        }

        this.displayErrorToast();
    }
}
