<div class="container container-card">
    <div class="container-header">
        <button type="button" class="button-icon" (click)="previous.emit()">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
        </button>
        <h2>{{ "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.confirmIdentityHeaderLabel" | transloco }}</h2>
    </div>
    <div class="container-content scroll-shadows">
        <p>{{ "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.providePhoneNumberMessage" | transloco }}</p>
        <dtm-ui-phone-number-field [formControl]="phoneNumberControl">
            <div *dtmUiFieldHasError="phoneNumberControl; name: 'required'" class="field-error">
                {{ "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.fieldRequiredError" | transloco }}
            </div>
            <div *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumber'" class="field-error">
                {{ "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.phoneNumberInvalidError" | transloco }}
            </div>
            <div *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumberType'" class="field-error">
                {{ "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.phoneNumberInvalidNumberTypeError" | transloco }}
            </div>
        </dtm-ui-phone-number-field>
    </div>
    <div class="container-actions">
        <button class="button-primary" type="submit" [disabled]="phoneNumberControl.invalid" (click)="submit()">
            {{ "uavIdClientLibReport.reportFormWizardVerifyIdentityStep.goToCodeVerificationButtonLabel" | transloco }}
        </button>
    </div>
</div>
