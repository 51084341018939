import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs";
import { FaqUrl } from "../../models/help.models";

const IMG_HEIGHT_PX = 72;
const IMG_WIDTH_PX = 72;

@Component({
    selector: "uav-id-client-lib-faq",
    templateUrl: "faq.component.html",
    styleUrls: ["../help.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent {
    protected readonly IMG_HEIGHT_PX = IMG_HEIGHT_PX;
    protected readonly IMG_WIDTH_PX = IMG_WIDTH_PX;
    protected readonly FaqUrl = FaqUrl;

    protected readonly selectedTopic$ = this.route.url.pipe(map((url) => url.pop()));

    constructor(private readonly route: ActivatedRoute) {}
}
