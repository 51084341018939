import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { SESSION_STORAGE } from "@dtm-frontend/shared/utils";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";
import { ACKNOWLEDGE_TOAST_ACTION } from "../../report-geolocation.component";

@Component({
    templateUrl: "remote-id-localization-warning-toast.component.html",
    styles: ["dtm-ui-icon { margin-right: 10px; }"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoteIdLocalizationWarningToastComponent extends Toast {
    constructor(
        @Inject(SESSION_STORAGE) private readonly sessionStorage: Storage,
        toastrService: ToastrService,
        toastPackage: ToastPackage
    ) {
        super(toastrService, toastPackage);
    }

    protected acknowledgeAndClose(): void {
        this.toastPackage.triggerAction(ACKNOWLEDGE_TOAST_ACTION);
        this.remove();
    }
}
