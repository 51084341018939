import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlSegment } from "@angular/router";
import { FaqComponent } from "./components/faq/faq.component";
import { HelpContainerComponent } from "./components/help-container/help-container.component";
import { FaqUrl } from "./models/help.models";

function faqUrlsMatcher(url: UrlSegment[]) {
    return url.length && Object.values(FaqUrl).includes(url[url.length - 1].toString() as FaqUrl) ? { consumed: url } : null;
}

const routes: Routes = [
    {
        path: "help",
        component: HelpContainerComponent,
        children: [
            {
                matcher: faqUrlsMatcher,
                component: FaqComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HelpRoutingModule {}
