import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { MENU_ITEMS, MenuItem } from "./menu-items";

interface MenuItemsComponentState {
    areIconsDisplayed: boolean;
}

const MY_REPORTS_ACCESS_ROLES = [UavIdRole.Civilian, UavIdRole.Officer, UavIdRole.DutyOfficer];
const MY_INTERVENTIONS_ACCESS_ROLES = [UavIdRole.Officer, UavIdRole.DutyOfficer];

@Component({
    selector: "uav-id-client-menu-items",
    templateUrl: "./menu-items.component.html",
    styleUrls: ["./menu-items.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MenuItemsComponent {
    @Input() public set areIconsDisplayed(value: boolean) {
        this.localStore.patchState({ areIconsDisplayed: value });
    }

    @Output() protected readonly itemClicked = new EventEmitter<MenuItem>();

    private readonly userRoles$ = this.store.select(AuthState.roles);
    protected readonly areIconsDisplayed$ = this.localStore.selectByKey("areIconsDisplayed");
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly hasMyReportsAccess$ = this.userRoles$.pipe(
        map((roles) => roles?.some((role) => MY_REPORTS_ACCESS_ROLES.includes(role as UavIdRole)))
    );
    protected readonly hasMyInterventionsAccess$ = this.userRoles$.pipe(
        map((roles) => roles?.some((role) => MY_INTERVENTIONS_ACCESS_ROLES.includes(role as UavIdRole)))
    );

    protected readonly MENU_ITEMS = MENU_ITEMS;

    constructor(private readonly localStore: LocalComponentStore<MenuItemsComponentState>, private readonly store: Store) {
        this.localStore.setState({ areIconsDisplayed: false });
    }
}
