import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";

const REGISTRATION_URL = "/registration";
const RESET_PASSWORD_URL = "/reset-password";

@UntilDestroy()
@Component({
    templateUrl: "login-page.component.html",
    styleUrls: ["../authorization.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {
    protected readonly isProcessing$ = this.store.select(AuthState.isProcessing);
    protected readonly hasLoginError$ = this.store.select(AuthState.hasLoginError);

    constructor(private readonly router: Router, private readonly store: Store) {}

    protected login({ username, password }: { username: string; password: string }): void {
        this.store.dispatch(new AuthActions.LogIn(username, password));
    }

    protected register(): void {
        this.router.navigateByUrl(REGISTRATION_URL);
    }

    protected resetPassword(): void {
        this.router.navigateByUrl(RESET_PASSWORD_URL);
    }
}
