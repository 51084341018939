import { InjectionToken } from "@angular/core";

export interface ReportEndpoints {
    sendReport: string;
    verifyIdentity: string;
    sendIdentityVerificationCode: string;
    resendIdentityVerificationCode: string;
}

export const REPORT_ENDPOINTS = new InjectionToken<ReportEndpoints>("Report endpoints");
export const IS_POLICE_HEADQUARTERS_LAYER_ENABLED = new InjectionToken<boolean>("Is police headquarters layer enabled");
