import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { AuthorizationState } from "../state/authorization.state";

@Injectable()
export class RegistrationVerificationGuard {
    constructor(private readonly store: Store, private readonly router: Router) {}

    public canActivate(): boolean {
        const authorizationData = this.store.selectSnapshot(AuthorizationState.authorizationData);
        const canAccess = !!authorizationData?.registrationId && !!authorizationData?.password;

        if (!canAccess) {
            this.router.navigateByUrl("/registration").catch(console.error);
        }

        return canAccess;
    }
}
