import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { DeviceSize, DeviceSizeService, UIState } from "@dtm-frontend/shared/ui";
import { APPROX_DAYS_IN_YEAR, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { IS_MOBILE_APP, PageService } from "@dtm-frontend/uav-identification-client-lib/shared";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { CookieService } from "ngx-cookie-service";
import { filter } from "rxjs";

interface AppComponentState {
    isCookiesInfoOpen: boolean;
}

const UAV_ID_CLIENT_COOKIE = "UAV_ID_CLIENT_COOKIE";

@UntilDestroy()
@Component({
    selector: "uav-id-client-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AppComponent {
    protected readonly isCookiesInfoOpen$ = this.localStore.selectByKey("isCookiesInfoOpen");
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage).pipe(RxjsUtils.filterFalsy());
    protected readonly isDesktop$ = this.deviceSizeService.getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide);

    constructor(
        private readonly cookieService: CookieService,
        private readonly deviceSizeService: DeviceSizeService,
        @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
        private readonly localStore: LocalComponentStore<AppComponentState>,
        private readonly pageService: PageService,
        private readonly router: Router,
        private readonly store: Store
    ) {
        this.localStore.setState({ isCookiesInfoOpen: !this.cookieService.check(UAV_ID_CLIENT_COOKIE) });

        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                untilDestroyed(this)
            )
            .subscribe((event) => this.pageService.setCurrentPage((event as NavigationEnd).url));
    }

    protected closeCookiesInfo(): void {
        this.cookieService.set(UAV_ID_CLIENT_COOKIE, "true", { expires: APPROX_DAYS_IN_YEAR });
        this.localStore.patchState({ isCookiesInfoOpen: false });
    }

    protected openCookiesInfo(): void {
        this.localStore.patchState({ isCookiesInfoOpen: true });
    }
}
