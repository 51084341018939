export enum UserProfileErrorType {
    Unknown = "Unknown",
    AlreadyExists = "AlreadyExists",
    InvalidCode = "InvalidCode",
    ExpiredCode = "ExpiredCode",
}

export interface UserProfileError {
    type: UserProfileErrorType;
}
