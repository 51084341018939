import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { PASSWORD_PATTERN } from "@dtm-frontend/shared/auth";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UpdatePasswordData } from "../../authorization.models";

enum UpdateStep {
    Password = "Password",
    Verification = "Verification",
}

interface UpdatePasswordComponentState {
    step: UpdateStep;
}

@Component({
    selector: "uav-id-client-lib-update-password",
    templateUrl: "update-password.component.html",
    styleUrls: ["update-password.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class UpdatePasswordComponent {
    @Output() protected readonly codeResend = new EventEmitter();
    @Output() protected readonly passwordUpdate = new EventEmitter<UpdatePasswordData>();

    protected readonly UpdateStep = UpdateStep;

    protected readonly step$ = this.localStore.selectByKey("step");

    protected readonly updatePasswordForm = new FormGroup(
        {
            password: new FormControl<string>("", {
                validators: [Validators.required, Validators.pattern(PASSWORD_PATTERN)],
                nonNullable: true,
                updateOn: "blur",
            }),
            passwordRepeat: new FormControl<string>("", {
                validators: [Validators.required],
                nonNullable: true,
                updateOn: "blur",
            }),
        },
        [this.passwordRepeatValidator()]
    );

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly localStore: LocalComponentStore<UpdatePasswordComponentState>
    ) {
        this.localStore.setState({
            step: UpdateStep.Password,
        });
    }

    // NOTE: Cleanup required for ionic view caching
    public resetComponentState(): void {
        this.updatePasswordForm.reset();
        this.localStore.patchState({ step: UpdateStep.Password });
    }

    protected goToVerificationStep() {
        this.updatePasswordForm.markAllAsTouched();

        if (this.updatePasswordForm.invalid) {
            return;
        }

        this.localStore.patchState({ step: UpdateStep.Verification });
    }

    protected updatePassword(code: string): void {
        this.passwordUpdate.emit({ code, newPassword: this.updatePasswordForm.controls.password.value });
    }

    private passwordRepeatValidator(): ValidatorFn {
        return (updatePasswordForm) => {
            const { password, passwordRepeat } = (updatePasswordForm as FormGroup)?.controls ?? {};

            return password.invalid || passwordRepeat.invalid || password?.value === passwordRepeat?.value
                ? null
                : { passwordRepeat: true };
        };
    }
}
