import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class PageService {
    private readonly previousPageSubject = new BehaviorSubject<string>("/");
    private readonly currentPageSubject = new BehaviorSubject<string>("/");

    public getPreviousPage(): string {
        return this.previousPageSubject.getValue();
    }

    public getCurrentPage(): string {
        return this.currentPageSubject.getValue();
    }

    public setCurrentPage(page: string): void {
        this.previousPageSubject.next(this.getCurrentPage());
        this.currentPageSubject.next(page);
    }
}
