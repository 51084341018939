<ng-container [ngSwitch]="selectedTopic$ | ngrxPush">
    <ng-container *ngSwitchCase="FaqUrl.WhenToReport">
        <h2>{{ "uavIdClientLibHelp.whenToReport.header" | transloco }}</h2>
        <div class="row-container">
            <div class="column-content content-with-image">
                <img ngSrc="assets/images/help-map.svg" alt="" [height]="IMG_HEIGHT_PX" [width]="IMG_WIDTH_PX" />
                <p>{{ "uavIdClientLibHelp.whenToReport.firstParagraph" | transloco }}</p>
            </div>

            <div class="column-content content-with-image">
                <img ngSrc="assets/images/help-danger.svg" alt="" [height]="IMG_HEIGHT_PX" [width]="IMG_WIDTH_PX" />
                <p>{{ "uavIdClientLibHelp.whenToReport.secondParagraph" | transloco }}</p>
            </div>

            <div class="column-content content-with-image">
                <img ngSrc="assets/images/help-recording.svg" alt="" [height]="IMG_HEIGHT_PX" [width]="IMG_WIDTH_PX" />
                <p>{{ "uavIdClientLibHelp.whenToReport.thirdParagraph" | transloco }}</p>
            </div>
        </div>

        <h2 class="subsection-header">{{ "uavIdClientLibHelp.whenToReport.illegalActivitiesHeader" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.whenToReport.illegalActivitiesFirstParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.whenToReport.illegalActivitiesSecondParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.whenToReport.illegalActivitiesThirdParagraph" | transloco }}</p>
        </div>
    </ng-container>

    <uav-id-client-lib-how-to-report *ngSwitchCase="FaqUrl.HowToReport"></uav-id-client-lib-how-to-report>

    <ng-container *ngSwitchCase="FaqUrl.CallEmergencyNumber">
        <h2>{{ "uavIdClientLibHelp.callEmergencyNumber.header" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.callEmergencyNumber.firstParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.callEmergencyNumber.secondParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.callEmergencyNumber.thirdParagraph" | transloco }}</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="FaqUrl.ApplicationPurpose">
        <h2>{{ "uavIdClientLibHelp.applicationPurpose.header" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.applicationPurpose.firstParagraph" | transloco }}</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="FaqUrl.DataSource">
        <h2>{{ "uavIdClientLibHelp.dataSource.header" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.dataSource.firstParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.dataSource.secondParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.dataSource.thirdParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.dataSource.forthParagraph" | transloco }}</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="FaqUrl.RemoteIdOrigin">
        <h2>{{ "uavIdClientLibHelp.remoteIdOrigin.header" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.remoteIdOrigin.firstParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.remoteIdOrigin.secondParagraph" | transloco }}</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="FaqUrl.RemoteIdDataMissing">
        <h2>{{ "uavIdClientLibHelp.remoteIdDataMissing.header" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.remoteIdDataMissing.firstParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.remoteIdDataMissing.secondParagraph" | transloco }}</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="FaqUrl.ReportProcess">
        <h2>{{ "uavIdClientLibHelp.reportProcess.header" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.reportProcess.firstParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.reportProcess.secondParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.reportProcess.thirdParagraph" | transloco }}</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="FaqUrl.ReportWithoutCall">
        <h2>{{ "uavIdClientLibHelp.reportWithoutCall.header" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.reportWithoutCall.firstParagraph" | transloco }}</p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="FaqUrl.ReportStatuses">
        <h2>{{ "uavIdClientLibHelp.reportStatuses.header" | transloco }}</h2>
        <div class="column-container">
            <p [innerHTML]="'uavIdClientLibHelp.reportStatuses.firstParagraph' | transloco"></p>
            <p [innerHTML]="'uavIdClientLibHelp.reportStatuses.secondParagraph' | transloco"></p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="FaqUrl.PhoneNumberVerification">
        <h2>{{ "uavIdClientLibHelp.phoneNumberVerification.header" | transloco }}</h2>
        <div class="column-container">
            <p>{{ "uavIdClientLibHelp.phoneNumberVerification.firstParagraph" | transloco }}</p>
            <p>{{ "uavIdClientLibHelp.phoneNumberVerification.secondParagraph" | transloco }}</p>
        </div>
    </ng-container>
</ng-container>
