import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output } from "@angular/core";
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Position } from "@dtm-frontend/shared/map";
import { LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { DeviceSize, DeviceSizeService, IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Flight } from "@dtm-frontend/uav-identification-shared-lib/flight";
import { ReportReason, ReportUavHeightEstimation } from "@dtm-frontend/uav-identification-shared-lib/report";
import { ReportFormData } from "../../../models/report.model";
import { IS_POLICE_HEADQUARTERS_LAYER_ENABLED } from "../../../report.tokens";

interface ReportStepDetailsComponentState {
    isProcessing: boolean;
    userPosition: Position | undefined;
    uavPosition: Position | undefined;
    flights: Flight[];
    policeHeadquartersLayerConfig: LeafletMapLayerConfig | undefined;
}

interface HeightEstimationRadioOption {
    value: ReportUavHeightEstimation;
    icon?: IconName;
}

const HEIGHT_ESTIMATION_RADIO_OPTIONS: HeightEstimationRadioOption[] = [
    { icon: "base-station", value: ReportUavHeightEstimation.AroundFive },
    { icon: "home-2", value: ReportUavHeightEstimation.AroundTen },
    { icon: "tree", value: ReportUavHeightEstimation.AroundTwentyFive },
    { icon: "building", value: ReportUavHeightEstimation.AboveFifty },
    { icon: "question", value: ReportUavHeightEstimation.CantDetermine },
];
const TEXTAREA_MAX_LENGTH = 200;
const INTERVENTION_NOTE_MAX_LENGTH = 2000;

@Component({
    selector: "uav-id-client-lib-report-step-details",
    templateUrl: "report-step-details.component.html",
    styleUrls: ["../report-steps-shared.component.scss", "report-step-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportStepDetailsComponent {
    @Input() public set isIntervention(value: boolean | undefined) {
        if (value) {
            this.interventionNoteControl.enable();

            return;
        }

        this.interventionNoteControl.disable();
    }
    @Input() public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set uavPosition(value: Position | undefined) {
        this.localStore.patchState({ uavPosition: value });
    }
    @Input() public set userPosition(value: Position | undefined) {
        this.localStore.patchState({ userPosition: value });
    }
    @Input() public set flights(value: Flight[] | undefined) {
        this.localStore.patchState({ flights: value });
    }
    @Input() public set policeHeadquartersLayerConfig(value: LeafletMapLayerConfig | undefined) {
        this.localStore.patchState({ policeHeadquartersLayerConfig: value });
    }

    @Output() protected readonly next = new EventEmitter<ReportFormData>();
    @Output() protected readonly previous = new EventEmitter<void>();

    protected readonly TEXTAREA_MAX_LENGTH = TEXTAREA_MAX_LENGTH;
    protected readonly INTERVENTION_NOTE_MAX_LENGTH = INTERVENTION_NOTE_MAX_LENGTH;
    protected readonly REPORT_REASONS = Object.values(ReportReason);
    protected readonly HEIGHT_ESTIMATION_RADIO_OPTIONS = HEIGHT_ESTIMATION_RADIO_OPTIONS;

    protected readonly isMapVisible$ = this.deviceSizeService.getSizeObservable(DeviceSize.Desktop, DeviceSize.DesktopWide);

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly userPosition$ = this.localStore.selectByKey("userPosition");
    protected readonly uavPosition$ = this.localStore.selectByKey("uavPosition");
    protected readonly flights$ = this.localStore.selectByKey("flights");
    protected readonly policeHeadquartersLayerConfig$ = this.localStore.selectByKey("policeHeadquartersLayerConfig");

    protected readonly reportForm: UntypedFormGroup;
    protected readonly uavHeightControl = new UntypedFormControl(null, Validators.required);
    protected readonly pilotIsVisibleControl = new UntypedFormControl(null, Validators.required);
    protected readonly pilotAppearanceControl = new UntypedFormControl(
        { value: null, disabled: !this.isPilotVisible() },
        Validators.maxLength(TEXTAREA_MAX_LENGTH)
    );
    protected readonly reasonsControl = new UntypedFormControl(null, Validators.required);
    protected readonly otherReasonsControl = new UntypedFormControl({ value: null, disabled: !this.isOtherReasonsCheckboxSelected() }, [
        Validators.required,
        Validators.maxLength(TEXTAREA_MAX_LENGTH),
    ]);
    protected readonly interventionNoteControl = new FormControl<string | null>({ value: null, disabled: true }, [
        Validators.maxLength(INTERVENTION_NOTE_MAX_LENGTH),
    ]);

    constructor(
        @Inject(IS_POLICE_HEADQUARTERS_LAYER_ENABLED) protected readonly isPoliceHeadquartersLayerEnabled: boolean,
        private readonly deviceSizeService: DeviceSizeService,
        private readonly localStore: LocalComponentStore<ReportStepDetailsComponentState>
    ) {
        this.localStore.setState({
            isProcessing: false,
            userPosition: undefined,
            uavPosition: undefined,
            flights: [],
            policeHeadquartersLayerConfig: undefined,
        });

        this.reportForm = new UntypedFormGroup({
            uav: new UntypedFormGroup({
                height: this.uavHeightControl,
            }),
            pilot: new UntypedFormGroup({
                isVisible: this.pilotIsVisibleControl,
                appearance: this.pilotAppearanceControl,
            }),
            reasons: this.reasonsControl,
            otherReasons: this.otherReasonsControl,
            interventionNote: this.interventionNoteControl,
        });
    }

    protected submit() {
        this.reportForm.markAllAsTouched();

        if (this.reportForm.invalid) {
            return;
        }

        this.next.emit(this.reportForm.value);
    }

    protected reasonsChange(): void {
        if (this.isOtherReasonsCheckboxSelected()) {
            if (this.otherReasonsControl.disabled) {
                this.otherReasonsControl.enable();
            }
        } else {
            if (this.otherReasonsControl.enabled) {
                this.otherReasonsControl.disable();
            }
        }
    }

    protected pilotVisibleChange(): void {
        if (this.isPilotVisible()) {
            this.pilotAppearanceControl.enable();
        } else {
            this.pilotAppearanceControl.disable();
        }
    }

    private isOtherReasonsCheckboxSelected(): boolean {
        return this.reasonsControl.value?.includes(ReportReason.Other);
    }

    private isPilotVisible(): boolean {
        return this.pilotIsVisibleControl.value;
    }
}
