import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { first } from "rxjs";

interface ReportStepSummaryComponentState {
    reportNumber: string | undefined;
}

const IMG_WIDTH = 213;
const IMG_HEIGHT = 213;

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-report-step-summary[reportNumber]",
    templateUrl: "report-step-summary.component.html",
    styleUrls: ["../report-steps-shared.component.scss", "report-step-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportStepSummaryComponent {
    @Input() public set reportNumber(value: string | undefined) {
        this.localStore.patchState({ reportNumber: value });
    }

    @Output() protected next = new EventEmitter<void>();

    protected readonly IMG_WIDTH = IMG_WIDTH;
    protected readonly IMG_HEIGHT = IMG_HEIGHT;

    public readonly reportNumber$ = this.localStore.selectByKey("reportNumber");

    constructor(
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<ReportStepSummaryComponentState>,
        private readonly router: Router,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            reportNumber: undefined,
        });
    }

    protected cancel(): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("uavIdClientLibReport.reportFormWizardSummaryStep.cancelPopupTitle"),
                    confirmationText: this.translocoService.translate(
                        "uavIdClientLibReport.reportFormWizardSummaryStep.cancelPopupDescription"
                    ),
                    declineButtonLabel: this.translocoService.translate(
                        "uavIdClientLibReport.reportFormWizardSummaryStep.cancelPopupCancelButtonLabel"
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "uavIdClientLibReport.reportFormWizardSummaryStep.cancelPopupConfirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(first(), RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.router.navigateByUrl("/");
            });
    }
}
