<div class="form-container">
    <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
        <div class="form-card">
            <h2 class="header">{{ "uavIdClientLibAuth.deleteAccount.header" | transloco }}</h2>

            <uav-id-client-lib-code-verification-template
                (codeResend)="resendVerificationCode()"
                (codeVerify)="confirmAccountDeletion($event)"
            >
                <p>{{ "uavIdClientLibAuth.deleteAccount.verificationCodeDescription" | transloco }}</p>
            </uav-id-client-lib-code-verification-template>

            <button type="button" class="button-secondary back-button" (click)="goToUserProfilePage()">
                {{ "uavIdClientLibAuth.deleteAccount.goBackToUserProfilePageButtonLabel" | transloco }}
            </button>
        </div>
    </dtm-ui-loader-container>
</div>
