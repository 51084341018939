<ng-container [ngTemplateOutlet]="menuItemTemplate" [ngTemplateOutletContext]="{ $implicit: MENU_ITEMS.home }"></ng-container>

<ng-container *ngIf="hasMyReportsAccess$ | ngrxPush">
    <ng-container [ngTemplateOutlet]="menuItemTemplate" [ngTemplateOutletContext]="{ $implicit: MENU_ITEMS.myReports }"></ng-container>
</ng-container>

<ng-container *ngIf="hasMyInterventionsAccess$ | ngrxPush">
    <ng-container
        [ngTemplateOutlet]="menuItemTemplate"
        [ngTemplateOutletContext]="{ $implicit: MENU_ITEMS.myInterventions }"
    ></ng-container>
</ng-container>

<ng-container [ngTemplateOutlet]="menuItemTemplate" [ngTemplateOutletContext]="{ $implicit: MENU_ITEMS.help }"></ng-container>

<ng-template #menuItemTemplate let-item>
    <button
        type="button"
        class="button-secondary menu-item"
        routerLinkActive="menu-item-active"
        [routerLink]="item.link"
        (click)="itemClicked.emit(item)"
    >
        <dtm-ui-icon *ngIf="areIconsDisplayed$ | ngrxPush" slot="start" [name]="item.icon"></dtm-ui-icon>
        <span>{{ item.labelKey | transloco }}</span>
    </button>
</ng-template>
