import { Type } from "@angular/core";
import { EnvironmentKeycloakConfig } from "@dtm-frontend/shared/auth";
import { GeolocationService, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import { LeafletMapConfig } from "@dtm-frontend/shared/map/leaflet";
import { UavAppUrls } from "@dtm-frontend/shared/map/leaflet-remote-id";
import { AuthorizationEndpoints } from "@dtm-frontend/uav-identification-client-lib/authorization";
import { NominatimGeocodingEndpoints, ReportEndpoints } from "@dtm-frontend/uav-identification-client-lib/report";
import { UserProfileEndpoints } from "@dtm-frontend/uav-identification-client-lib/user-profile";
import { FlightEndpoints } from "@dtm-frontend/uav-identification-shared-lib/flight";
import { ReportManagementEndpoints } from "@dtm-frontend/uav-identification-shared-lib/report";

export enum BuildType {
    Web = "web",
    Mobile = "mobile",
}

export interface UavIdentificationClientStaticConfiguration {
    buildType: BuildType;
    geolocationService: Type<GeolocationService>;
    keycloakClientId: string;
}

export interface UavIdentificationClientEnvironment extends UavIdentificationClientStaticConfiguration {
    production: boolean;
    name: string;
    keycloakConfig: EnvironmentKeycloakConfig;
    azureMapsSubscriptionKey: string;
    bearerExcludedUrls: never[];
    leafletMapConfig: LeafletMapConfig;
    reportEndpoints: ReportEndpoints;
    nominatimGeocodingEndpoints: NominatimGeocodingEndpoints;
    authorizationEndpoints: AuthorizationEndpoints;
    sharedMapEndpoints: SharedMapEndpoints;
    reportManagementEndpoints: ReportManagementEndpoints;
    flightEndpoints: FlightEndpoints;
    userProfileEndpoints: UserProfileEndpoints;
    uavAppUrls: UavAppUrls;
    termsOfUseUrl: string;
    accessibilityStatementUrl: string;
    websocketEndpoint: string;
}
