import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { RouterModule } from "@angular/router";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { IonicModule } from "@ionic/angular";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxMaskModule } from "ngx-mask";
import { CardTemplateComponent } from "./components/card-template/card-template.component";
import { CodeVerificationTemplateComponent } from "./components/code-verification-template/code-verification-template.component";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        NgxMaskModule.forRoot(),
        MatInputModule,
        LetModule,
        PushModule,
        ReactiveFormsModule,
        RouterModule,
        SharedI18nModule,
        SharedUiModule,
    ],
    declarations: [CardTemplateComponent, CodeVerificationTemplateComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "uavIdClientLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [CardTemplateComponent, CodeVerificationTemplateComponent],
})
export class SharedModule {}
