import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { KEYCLOAK_CONFIG, OfflineCapableAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, GeolocationService, SHARED_MAP_ENDPOINTS } from "@dtm-frontend/shared/map";
import { LEAFLET_MAP_CONFIG } from "@dtm-frontend/shared/map/leaflet";
import { UAV_APP_URLS } from "@dtm-frontend/shared/map/leaflet-remote-id";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import {
    ACCESSIBILITY_STATEMENT_URL,
    FunctionUtils,
    LOCAL_STORAGE,
    Logger,
    LoggerModule,
    SESSION_STORAGE,
    TERMS_OF_USE_URL,
} from "@dtm-frontend/shared/utils";
import { SharedWebsocketModule, SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import { AUTHORIZATION_ENDPOINTS, AuthorizationModule } from "@dtm-frontend/uav-identification-client-lib/authorization";
import { MyInterventionsModule } from "@dtm-frontend/uav-identification-client-lib/my-interventions";
import { MyReportsModule } from "@dtm-frontend/uav-identification-client-lib/my-reports";
import {
    GEOCODING_ENDPOINTS,
    IS_POLICE_HEADQUARTERS_LAYER_ENABLED,
    REPORT_ENDPOINTS,
    ReportModule,
} from "@dtm-frontend/uav-identification-client-lib/report";
import { IS_MOBILE_APP, UavIdentificationClientInterceptor } from "@dtm-frontend/uav-identification-client-lib/shared";
import { USER_PROFILE_ENDPOINTS, UserProfileModule } from "@dtm-frontend/uav-identification-client-lib/user-profile";
import { FLIGHT_ENDPOINTS, FlightModule } from "@dtm-frontend/uav-identification-shared-lib/flight";
import { REPORT_MANAGEMENT_ENDPOINTS } from "@dtm-frontend/uav-identification-shared-lib/report";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule } from "@ngxs/store";
import { first, tap } from "rxjs/operators";
import { BuildType, UavIdentificationClientEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { MenuItemsComponent } from "./components/menu/menu-items/menu-items.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://dd896db4d71db7c1c2af4248d2ac0d4c@sentry.pansa.cloud/13");

export function createAppModule(environment: UavIdentificationClientEnvironment) {
    @NgModule({
        declarations: [AppComponent, HeaderComponent, MenuComponent, MenuItemsComponent],
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AuthorizationModule,
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            FlightModule,
            HttpClientModule,
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            IonicModule.forRoot(),
            LetModule,
            MatTooltipModule,
            MyInterventionsModule,
            MyReportsModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "UavIdentificationClient",
            }),
            OfflineCapableAuthModule,
            PushModule,
            ReportModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            SharedToastModule.forRoot({
                positionClass: "toast-top-center",
            }),
            SharedUiModule,
            SharedWebsocketModule.forRoot(),
            UserProfileModule,
        ],
        providers: [
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: UavIdentificationClientInterceptor,
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "uavIdClient",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: GeolocationService,
                useClass: environment.geolocationService,
            },
            {
                provide: LEAFLET_MAP_CONFIG,
                useValue: environment.leafletMapConfig,
            },
            {
                provide: AUTHORIZATION_ENDPOINTS,
                useValue: environment.authorizationEndpoints,
            },
            {
                provide: FLIGHT_ENDPOINTS,
                useValue: environment.flightEndpoints,
            },
            {
                provide: GEOCODING_ENDPOINTS,
                useValue: environment.nominatimGeocodingEndpoints,
            },
            {
                provide: REPORT_ENDPOINTS,
                useValue: environment.reportEndpoints,
            },
            {
                provide: REPORT_MANAGEMENT_ENDPOINTS,
                useValue: environment.reportManagementEndpoints,
            },
            {
                provide: SHARED_MAP_ENDPOINTS,
                useValue: environment.sharedMapEndpoints,
            },
            {
                provide: USER_PROFILE_ENDPOINTS,
                useValue: environment.userProfileEndpoints,
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: UAV_APP_URLS,
                useValue: environment.uavAppUrls,
            },
            {
                provide: IS_POLICE_HEADQUARTERS_LAYER_ENABLED,
                useValue: !environment.production,
            },
            {
                provide: IS_MOBILE_APP,
                useValue: environment.buildType === BuildType.Mobile,
            },
            {
                provide: TERMS_OF_USE_URL,
                useValue: environment.termsOfUseUrl,
            },
            {
                provide: ACCESSIBILITY_STATEMENT_URL,
                useValue: environment.accessibilityStatementUrl,
            },
            { provide: LOCAL_STORAGE, useValue: localStorage },
            { provide: SESSION_STORAGE, useValue: sessionStorage },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (): SharedWebsocketModuleConfig => ({
                    endpoint: environment.websocketEndpoint,
                }),
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(translocoHelper: TranslationHelperService, titleService: Title) {
            const gotTitle$ = translocoHelper
                .waitForTranslation("uavIdClient.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)));

            gotTitle$
                .pipe(
                    first(),
                    tap(() => {
                        document.getElementById("loader")?.remove();
                    })
                )
                .subscribe();
        }
    }

    return AppModule;
}
