import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ReportFormWizardComponent } from "./components/report-form-wizard/report-form-wizard.component";
import { ReportGeolocationComponent } from "./components/report-geolocation/report-geolocation.component";
import { CanEnterReportFormGuard } from "./guards/can-enter-report-form.guard";

const routes: Routes = [
    {
        path: "report/user-geolocation",
        component: ReportGeolocationComponent,
    },
    {
        path: "report/form",
        component: ReportFormWizardComponent,
        canActivate: [CanEnterReportFormGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ReportRoutingModule {}
