import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { ReportSummaryResolver } from "@dtm-frontend/uav-identification-shared-lib/report";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { MyInterventionPreviewComponent } from "./components/my-intervention-preview/my-intervention-preview.component";
import { MyInterventionsListContainerComponent } from "./components/my-interventions-list-container/my-interventions-list-container.component";

const routes: Routes = [
    {
        path: "my-interventions",
        component: MyInterventionsListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            roles: [UavIdRole.Officer, UavIdRole.DutyOfficer],
        },
    },
    {
        path: "my-interventions/:id",
        component: MyInterventionPreviewComponent,
        canActivate: [DtmRoleGuard],
        resolve: {
            init: ReportSummaryResolver,
        },
        data: {
            roles: [UavIdRole.Officer, UavIdRole.DutyOfficer],
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [ReportSummaryResolver],
    exports: [RouterModule],
})
export class MyInterventionsRoutingModule {}
