import { Injectable } from "@angular/core";
import { OfficerEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, catchError, finalize, tap } from "rxjs";
import { UserProfileError } from "../models/user-profile.models";
import { UserProfileApiService } from "../services/user-profile-api.service";
import { UserProfileActions } from "./user-profile.actions";

export interface UserProfileStateModel {
    isProcessing: boolean;
    userData: OfficerEntity | undefined;
    userDataError: UserProfileError | undefined;
    emailUpdateRequestId: string | undefined;
    requestEmailUpdateError: UserProfileError | undefined;
    phoneNumberUpdateRequestId: string | undefined;
    requestPhoneNumberUpdateError: UserProfileError | undefined;
    updateEmailError: UserProfileError | undefined;
    updatePhoneNumberError: UserProfileError | undefined;
}

const defaultState: UserProfileStateModel = {
    isProcessing: false,
    userData: undefined,
    userDataError: undefined,
    emailUpdateRequestId: undefined,
    requestEmailUpdateError: undefined,
    phoneNumberUpdateRequestId: undefined,
    requestPhoneNumberUpdateError: undefined,
    updateEmailError: undefined,
    updatePhoneNumberError: undefined,
};

@State<UserProfileStateModel>({
    name: "userProfile",
    defaults: defaultState,
})
@Injectable()
export class UserProfileState {
    @Selector()
    public static isProcessing(state: UserProfileStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static userData(state: UserProfileStateModel): OfficerEntity | undefined {
        return state.userData;
    }

    @Selector()
    public static userDataError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.userDataError;
    }

    @Selector()
    public static requestEmailUpdateError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.requestEmailUpdateError;
    }

    @Selector()
    public static requestPhoneNumberUpdateError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.requestPhoneNumberUpdateError;
    }

    @Selector()
    public static updateEmailError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.updateEmailError;
    }

    @Selector()
    public static updatePhoneNumberError(state: UserProfileStateModel): UserProfileError | undefined {
        return state.updatePhoneNumberError;
    }

    constructor(private readonly userProfileApi: UserProfileApiService) {}

    @Action(UserProfileActions.GetUserData)
    public getUserData(context: StateContext<UserProfileStateModel>, { userId }: UserProfileActions.GetUserData) {
        context.patchState({ isProcessing: true, userDataError: undefined });

        return this.userProfileApi.getUserData(userId).pipe(
            tap((userData) => context.patchState({ userData })),
            catchError((userDataError) => {
                context.patchState({ userDataError });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(UserProfileActions.RequestEmailUpdate)
    public requestEmailUpdate(context: StateContext<UserProfileStateModel>, { email }: UserProfileActions.RequestEmailUpdate) {
        context.patchState({ emailUpdateRequestId: undefined, requestEmailUpdateError: undefined });

        const userId = context.getState().userData?.id;
        if (!userId) {
            return;
        }

        return this.userProfileApi.requestEmailUpdate(userId, email).pipe(
            tap((updateRequestResponse) =>
                context.patchState({ emailUpdateRequestId: updateRequestResponse.communicationChannelUpdateRequestId })
            ),
            catchError((error) => {
                context.patchState({ requestEmailUpdateError: error });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.UpdateEmail)
    public updateEmail(context: StateContext<UserProfileStateModel>, { email, verificationCode }: UserProfileActions.UpdateEmail) {
        context.patchState({ updateEmailError: undefined });

        const { emailUpdateRequestId, userData } = context.getState();
        if (!userData?.id || !emailUpdateRequestId) {
            return;
        }

        return this.userProfileApi.updateEmail(userData.id, emailUpdateRequestId, verificationCode).pipe(
            tap(() =>
                context.patchState({
                    userData: { ...userData, email },
                })
            ),
            catchError((error) => {
                context.patchState({ updateEmailError: error });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.RequestPhoneNumberUpdate)
    public requestPhoneNumberUpdate(
        context: StateContext<UserProfileStateModel>,
        { phoneNumber }: UserProfileActions.RequestPhoneNumberUpdate
    ) {
        context.patchState({ phoneNumberUpdateRequestId: undefined, requestPhoneNumberUpdateError: undefined });

        const userId = context.getState().userData?.id;
        if (!userId) {
            return;
        }

        return this.userProfileApi.requestPhoneNumberUpdate(userId, phoneNumber).pipe(
            tap((updateRequestResponse) =>
                context.patchState({ phoneNumberUpdateRequestId: updateRequestResponse.communicationChannelUpdateRequestId })
            ),
            catchError((error) => {
                context.patchState({ requestPhoneNumberUpdateError: error });

                return EMPTY;
            })
        );
    }

    @Action(UserProfileActions.UpdatePhoneNumber)
    public updatePhoneNumber(
        context: StateContext<UserProfileStateModel>,
        { phoneNumber, verificationCode }: UserProfileActions.UpdatePhoneNumber
    ) {
        context.patchState({ updatePhoneNumberError: undefined });

        const { phoneNumberUpdateRequestId, userData } = context.getState();
        if (!userData?.id || !phoneNumberUpdateRequestId) {
            return;
        }

        return this.userProfileApi.updatePhoneNumber(userData.id, phoneNumberUpdateRequestId, verificationCode).pipe(
            tap(() =>
                context.patchState({
                    userData: { ...userData, phoneNumber },
                })
            ),
            catchError((error) => {
                context.patchState({ updatePhoneNumberError: error });

                return EMPTY;
            })
        );
    }
}
