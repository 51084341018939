import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionDispatched } from "@ngxs/store";
import { tap } from "rxjs/operators";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class ActionsHandler {
    constructor(private readonly actions$: Actions, private readonly router: Router) {
        this.handleAuthGoToNotAuthorizedPage();
    }

    private handleAuthGoToNotAuthorizedPage() {
        this.actions$
            .pipe(
                ofActionDispatched(AuthActions.GoToNotAuthorizedPage),
                tap(() => this.router.navigate(["/"])),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
