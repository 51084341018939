import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { UavIdentificationClientEnvironment } from "./environment.model";
import { staticConfig } from "./static-config";

export const dynamicEnvironment = createDynamicEnvironment<UavIdentificationClientEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig, uavAppUrl, sharedMapEndpoints, environment }) => {
        const apiUrl = `https://${apiDomain}/api`;
        const websocketEndpoint = `wss://${apiDomain}/api/ws`;

        return {
            production: environment !== "dev",
            name: environment,
            keycloakConfig: { ...keycloakConfig, clientId: staticConfig.keycloakClientId },
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            bearerExcludedUrls: [],
            leafletMapConfig: {
                defaultPosition: {
                    lat: 52.115,
                    lng: 19.424,
                },
                zoom: {
                    min: 6,
                    max: 18,
                    initial: 6,
                    userPosition: 15,
                },
            },
            authorizationEndpoints: {
                register: `${apiUrl}/uav-identification/enrollments`,
                verifyRegistration: `${apiUrl}/uav-identification/enrollments/{{registrationId}}`,
                resendRegistrationVerificationCode: `${apiUrl}/uav-identification/enrollments/{{registrationId}}/code`,
                getOfficerInstitutions: `${apiUrl}/uav-identification/uniformed-services`,
                getOfficerUnits: `${apiUrl}/uav-identification/uniformed-services/{{institutionId}}/officer-units`,
                resetPassword: `${apiUrl}/uav-identification/users/reset-password-request`,
                updatePassword: `${apiUrl}/uav-identification/password-modification-requests/{{requestId}}`,
                resendUpdatePasswordVerificationCode: `${apiUrl}/uav-identification/password-modification-requests/{{requestId}}/code`,
                changePassword: `${apiUrl}/uav-identification/users/{{userId}}/change-password-request`,
                requestAccountDeletion: `${apiUrl}/uav-identification/users/{{userId}}/delete-account-request`,
                confirmAccountDeletion: `${apiUrl}/uav-identification/delete-account-requests/{{requestId}}`,
                resendAccountDeletionVerificationCode: `${apiUrl}/uav-identification/delete-account-requests/{{requestId}}/code`,
            },
            flightEndpoints: {
                getFlights: `${apiUrl}/uav-identification/flights/active`,
                wsFlightsTopic: "/websocket/topic/uav-identification/flight/all",
            },
            sharedMapEndpoints,
            nominatimGeocodingEndpoints: {
                search: "https://nominatim.openstreetmap.org/search",
            },
            reportEndpoints: {
                sendReport: `${apiUrl}/uav-identification/reports`,
                verifyIdentity: `${apiUrl}/uav-identification/identities`,
                sendIdentityVerificationCode: `${apiUrl}/uav-identification/identities/{{verificationId}}`,
                resendIdentityVerificationCode: `${apiUrl}/uav-identification/identities/{{verificationId}}/code`,
            },
            reportManagementEndpoints: {
                getReports: `${apiUrl}/uav-identification/reports`,
                getReportSummary: `${apiUrl}/uav-identification/reports/{{reportId}}`,
                getActiveOfficers: `${apiUrl}/uav-identification/users/officers/active`,
                getOfficers: `${apiUrl}/uav-identification/users/officers`,
                updateAssignedOfficer: `${apiUrl}/uav-identification/reports/{{id}}/intervening-officer`,
                updateNote: `${apiUrl}/uav-identification/reports/{{id}}/intervention-note`,
                updateStatus: `${apiUrl}/uav-identification/reports/{{id}}/status`,
                updateOfficerUnit: `${apiUrl}/uav-identification/reports/{{id}}/officer-unit`,
                getReportGeoZoneDetails: `${apiUrl}/uav-identification/geo-zones/{{zoneId}}/information`,
            },
            userProfileEndpoints: {
                changePassword: `${apiUrl}/uav-identification/users/{{id}}/reset-password`,
                getUser: `${apiUrl}/uav-identification/users/{{id}}`,
                requestEmailUpdate: `${apiUrl}/uav-identification/users/{{id}}/email-update-request`,
                requestPhoneNumberUpdate: `${apiUrl}/uav-identification/users/{{id}}/phone-number-update-request`,
                updateEmail: `${apiUrl}/uav-identification/users/{{id}}/email-update-request/{{updateRequestId}}`,
                updatePhoneNumber: `${apiUrl}/uav-identification/users/{{id}}/phone-number-update-request/{{updateRequestId}}`,
            },
            uavAppUrls: {
                operatorProfile: `${uavAppUrl}/(public:operator/{{operatorId}})`,
                pilotProfile: `${uavAppUrl}/(public:pilot/{{pilotId}})`,
            },
            termsOfUseUrl: `${apiUrl}/terms-of-service/uav-identification/current`,
            accessibilityStatementUrl: `${apiUrl}/accessibility-statement`,
            websocketEndpoint,
            ...staticConfig,
        };
    }
);
