import { Logger } from "@dtm-frontend/shared/utils";
import { IMessage } from "@stomp/rx-stomp";
import { Flight, FlightEvent, FlightEventMessage, FlightSearchArea } from "../models/flight.models";

export interface FlightsResponseBody {
    flights: Flight[];
    circle: FlightSearchArea;
}

type FlightCreatedOrUpdatedEventBody = Omit<Flight, "startTime" | "endTime"> & { startTime: string; endTime: string };

function convertFlightCreatedOrUpdatedEventBodyToFlight(eventBody: FlightCreatedOrUpdatedEventBody): Flight {
    const { startTime, endTime, ...flightProperties } = eventBody;

    return {
        ...flightProperties,
        startTime: new Date(startTime),
        endTime: new Date(endTime),
    };
}

export function convertIMessageToFlightEventMessage(message: IMessage): FlightEventMessage {
    const eventType = message.headers["event-type"] as unknown as FlightEvent;
    let parsedBody;

    try {
        parsedBody = JSON.parse(message.body);
    } catch (error) {
        Logger.captureException(error, {
            level: "error",
            extra: { context: `JSON parsing uav-identification-client WS flight event - ${eventType}` },
        });
    }

    switch (eventType) {
        case FlightEvent.FlightCreated:
        case FlightEvent.FlightUpdated:
            return { type: eventType, body: convertFlightCreatedOrUpdatedEventBodyToFlight(parsedBody) };
        case FlightEvent.FlightRemoved:
            return { type: eventType, body: parsedBody };
    }
}
