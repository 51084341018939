import { PhoneNumber } from "@dtm-frontend/shared/ui";

export namespace UserProfileActions {
    export class GetUserData {
        public static readonly type = "[User profile] Get user data";
        constructor(public userId: string) {}
    }

    export class RequestEmailUpdate {
        public static readonly type = "[User Profile] Request email update";
        constructor(public email: string) {}
    }

    export class RequestPhoneNumberUpdate {
        public static readonly type = "[User Profile] Request phone number update";
        constructor(public phoneNumber: PhoneNumber) {}
    }

    export class UpdateEmail {
        public static readonly type = "[User Profile] Update email";
        constructor(public email: string, public verificationCode: string) {}
    }

    export class UpdatePhoneNumber {
        public static readonly type = "[User Profile] Update phone number";
        constructor(public phoneNumber: PhoneNumber, public verificationCode: string) {}
    }
}
