<div class="container container-card">
    <div dtmUiInvalidFormScrollable class="container-content scroll-shadows">
        <uav-id-client-lib-how-to-report></uav-id-client-lib-how-to-report>

        <div *ngIf="isNotLoggedIn$ | ngrxPush" class="terms-of-use-container">
            <dtm-ui-checkbox-field class="checkbox-with-link" [formControl]="areTermsOfUseAcceptedControl">
                <span>{{ "uavIdClientLibReport.reportFormWizardHowToReportStep.acceptLabel" | transloco }}</span>
                <a
                    [href]="termsOfUseUrl"
                    rel="noopener noreferrer"
                    target="_blank"
                    class="button-link custom"
                    (click)="$event.stopPropagation()"
                    >{{ "uavIdClientLibReport.reportFormWizardHowToReportStep.termsOfUseLabel" | transloco }}</a
                >
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors class="preserve-error-space">
                <div *dtmUiFieldHasError="areTermsOfUseAcceptedControl; name: 'required'">
                    {{ "uavIdClientLibReport.reportFormWizardHowToReportStep.fieldRequiredError" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </div>
    </div>

    <div class="container-actions">
        <button class="button-secondary" type="button" (click)="goBack()">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
            {{ "uavIdClientLibReport.reportFormWizardHowToReportStep.goBackButtonLabel" | transloco }}
        </button>
        <div class="coupled-actions">
            <button *ngIf="canIntervene$ | ngrxPush" class="button-secondary" type="button" (click)="next.emit(ReportType.Intervention)">
                {{ "uavIdClientLibReport.reportFormWizardHowToReportStep.reportInterventionButtonLabel" | transloco }}
            </button>
            <button class="button-primary" type="button" (click)="goToNextStep()">
                {{ "uavIdClientLibReport.reportFormWizardHowToReportStep.reportButtonLabel" | transloco }}
            </button>
        </div>
    </div>
</div>
