import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from "@angular/core";
import { DeviceSize, DeviceSizeService, UIState } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { IS_MOBILE_APP } from "@dtm-frontend/uav-identification-client-lib/shared";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";

const DESKTOP_MIN_WIDTH_BREAKPOINT = "(min-width: 1200px)";

@Component({
    selector: "uav-id-client-menu",
    templateUrl: "menu.component.html",
    styleUrls: ["menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    @Output() protected readonly cookiesInfoOpen = new EventEmitter();

    protected readonly isDesktop$ = this.breakpointObserver.observe(DESKTOP_MIN_WIDTH_BREAKPOINT).pipe(map(({ matches }) => matches));
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage).pipe(RxjsUtils.filterFalsy());
    protected readonly isSmartphoneOrTablet$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide,
        DeviceSize.Tablet
    );

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        private readonly deviceSizeService: DeviceSizeService,
        @Inject(IS_MOBILE_APP) protected readonly isMobileApp: boolean,
        private readonly store: Store
    ) {}
}
