<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start" class="ion-hide-xl-up">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>

        <div slot="start" class="logo desktop-menu">
            <a routerLink="/">
                <img src="assets/images/logo.svg" alt="logo" />
            </a>
        </div>

        <uav-id-client-menu-items class="desktop-menu ion-hide-xl-down"></uav-id-client-menu-items>

        <ng-container *ngIf="isAdmin$ | ngrxPush; else defaultReportButtonTemplate">
            <div slot="end" [matTooltip]="'uavIdClient.reportButtonDisabledForAdminMessage' | transloco">
                <button type="button" class="button-primary report-button ion-hide-xl-down" routerLink="/report/form" disabled>
                    {{ "uavIdClient.reportButtonLabel" | transloco }}
                </button>
            </div>
        </ng-container>

        <dtm-ui-header-user-button
            slot="end"
            [isUserLoggedIn]="isLoggedIn$ | ngrxPush"
            [userName]="userName$ | ngrxPush"
            [isShortMode]="isHeaderUserButtonShortMode$ | ngrxPush"
            (login)="login()"
            (logout)="logout()"
            (goToUserProfilePage)="goToUserProfile()"
        ></dtm-ui-header-user-button>
    </ion-toolbar>
</ion-header>

<ng-template #defaultReportButtonTemplate>
    <div
        slot="end"
        *ngrxLet="userPosition$ as isUserPositionProvided"
        [matTooltip]="(isUserPositionProvided ? '' : 'uavIdClient.reportButtonActivationRequirementMessage') | transloco"
    >
        <button
            type="button"
            class="button-primary report-button ion-hide-xl-down"
            routerLink="/report/form"
            [disabled]="!isUserPositionProvided"
        >
            {{ "uavIdClient.reportButtonLabel" | transloco }}
        </button>
    </div>
</ng-template>
