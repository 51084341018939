<div class="container">
    <div class="logo ion-hide-md-down">
        <img src="/assets/images/auth-logo.svg" />
    </div>
    <dtm-auth-login
        [isProcessing]="isProcessing$ | ngrxPush"
        [hasLoginError]="hasLoginError$ | ngrxPush"
        (login)="login($event)"
        (register)="register()"
        (passwordReset)="resetPassword()"
    ></dtm-auth-login>
</div>
