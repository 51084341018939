import { InjectionToken } from "@angular/core";

export interface AuthorizationEndpoints {
    register: string;
    verifyRegistration: string;
    resendRegistrationVerificationCode: string;
    getOfficerInstitutions: string;
    getOfficerUnits: string;
    changePassword: string;
    resetPassword: string;
    updatePassword: string;
    resendUpdatePasswordVerificationCode: string;
    requestAccountDeletion: string;
    confirmAccountDeletion: string;
    resendAccountDeletionVerificationCode: string;
}

export const AUTHORIZATION_ENDPOINTS = new InjectionToken<AuthorizationEndpoints>("Authorization Endpoints");
