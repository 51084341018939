import { HttpParams } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { SHARED_MAP_ENDPOINTS, SharedMapEndpoints } from "@dtm-frontend/shared/map";
import { LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import {
    Report,
    ReportGeoZoneDialogComponent,
    ReportGeoZoneInfo,
    ReportInterventionNoteDialogService,
    ReportManagementActions,
    ReportManagementState,
    ReportStatus,
} from "@dtm-frontend/uav-identification-shared-lib/report";
import { StatusChange } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { firstValueFrom, switchMap, tap } from "rxjs";

interface MyInterventionPreviewComponentState {
    isStatusUpdateProcessing: boolean;
}

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-my-intervention-preview",
    templateUrl: "./my-intervention-preview.component.html",
    styleUrls: ["./my-intervention-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class MyInterventionPreviewComponent {
    protected readonly hasGetReportSummaryErrorOccurred$ = this.store.select(ReportManagementState.hasGetReportSummaryErrorOccurred);
    protected readonly isStatusUpdateProcessing$ = this.localStore.selectByKey("isStatusUpdateProcessing");
    protected readonly reportSummary$ = this.store.select(ReportManagementState.reportSummary);
    protected readonly selectedReportGeoZonesInfo$ = this.store.select(ReportManagementState.selectedReportGeoZonesInfo);
    protected readonly selectedReportGeoZonesInfoError$ = this.store.select(ReportManagementState.selectedReportGeoZonesInfoError);
    protected readonly isSelectedReportGeoZonesInfoLoading$ = this.store.select(ReportManagementState.isSelectedReportGeoZonesInfoLoading);

    protected readonly AVAILABLE_UPDATE_STATUSES: ReportStatus[] = [
        ReportStatus.UnderAnalysis,
        ReportStatus.Completed,
        ReportStatus.Rejected,
        ReportStatus.Unconfirmed,
        ReportStatus.FurtherInvestigationRequired,
    ];

    protected readonly REPORT_GEO_ZONES_LAYER_CONFIG: LeafletMapLayerConfig = {
        type: "WMS",
        baseUrl: this.sharedMapEndpoints.geoServerEndpoint,
        options: {
            layers: "uav-identification-admin",
            format: "image/png",
            transparent: true,
        },
    };

    constructor(
        private readonly dialogService: DialogService,
        private readonly interventionNoteDialogService: ReportInterventionNoteDialogService,
        private readonly localStore: LocalComponentStore<MyInterventionPreviewComponentState>,
        @Inject(SHARED_MAP_ENDPOINTS) private readonly sharedMapEndpoints: SharedMapEndpoints,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {
        this.localStore.setState({
            isStatusUpdateProcessing: false,
        });
    }

    protected async updateStatus(statusChange: StatusChange, report: Report) {
        this.localStore.patchState({ isStatusUpdateProcessing: true });
        await firstValueFrom(
            this.store
                .dispatch(new ReportManagementActions.UpdateReportStatus({ id: report.id, ...statusChange }))
                .pipe(untilDestroyed(this))
        );

        const error = this.store.selectSnapshot(ReportManagementState.reportManagementError);

        if (error) {
            this.toastrService.error(
                this.translocoService.translate("uavIdClientLibMyInterventions.myInterventionPreview.statusUpdateUnknownErrorMessage")
            );
            this.localStore.patchState({ isStatusUpdateProcessing: false });

            return;
        }

        this.toastrService.success(
            this.translocoService.translate("uavIdClientLibMyInterventions.myInterventionPreview.statusUpdateSuccessMessage")
        );
        this.reloadData(report.id);
    }

    protected openInterventionNoteDialog(data: Report) {
        this.interventionNoteDialogService
            .openDialog(data)
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                tap(() => this.reloadData(data.id)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    protected getSelectedZonesInfo(params: HttpParams): void {
        this.store.dispatch(new ReportManagementActions.GetReportGeoZonesInfo(params));
    }

    protected openZoneDetailsDialog(zone: ReportGeoZoneInfo): void {
        this.store
            .dispatch(new ReportManagementActions.GetReportGeoZoneDetails(zone.id))
            .pipe(
                switchMap(() =>
                    this.dialogService
                        .open(ReportGeoZoneDialogComponent, {
                            data: {
                                zone,
                                details: this.store.selectSnapshot(ReportManagementState.selectedReportGeoZoneDetails),
                                hasError: this.store.selectSnapshot(ReportManagementState.selectedReportGeoZoneDetailsError),
                            },
                        })
                        .afterClosed()
                ),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private reloadData(reportId: string) {
        this.store
            .dispatch(new ReportManagementActions.GetReportSummary(reportId))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.localStore.patchState({ isStatusUpdateProcessing: false }));
    }
}
