<section class="header">
    <button type="button" class="button-secondary" routerLink="..">
        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
        {{ "uavIdClientLibMyInterventions.myInterventionPreview.goBackToListButtonLabel" | transloco }}
    </button>
</section>

<ng-container *ngrxLet="{ reportSummary: reportSummary$, hasGetReportSummaryErrorOccurred: hasGetReportSummaryErrorOccurred$ } as vm">
    <ng-container *ngIf="!vm.hasGetReportSummaryErrorOccurred; else errorMessageTemplate">
        <section *ngIf="vm.reportSummary?.report as report" class="content">
            <div class="data-panel">
                <div class="data-panel-content">
                    <section class="data-panel-header">
                        <h2>
                            {{
                                "uavIdClientLibMyInterventions.myInterventionPreview.interventionHeaderLabel"
                                    | transloco : { number: report.number }
                            }}
                        </h2>
                        <button
                            *ngIf="!report.interventionNote"
                            type="button"
                            class="button-secondary"
                            (click)="openInterventionNoteDialog(report)"
                        >
                            <dtm-ui-icon name="sticky-note"></dtm-ui-icon>
                            {{ "uavIdClientLibMyInterventions.myInterventionPreview.addNotesButtonLabel" | transloco }}
                        </button>
                    </section>

                    <uav-id-shared-lib-report-preview-intervention-note
                        *ngIf="report.interventionNote"
                        [report]="report"
                        (dialogOpen)="openInterventionNoteDialog(report)"
                    ></uav-id-shared-lib-report-preview-intervention-note>

                    <uav-id-shared-lib-report-preview-user [user]="report.reportingUser"></uav-id-shared-lib-report-preview-user>

                    <uav-id-shared-lib-report-preview-details
                        [report]="report"
                        [canChangeStatus]="true"
                        [availableUpdateStatuses]="AVAILABLE_UPDATE_STATUSES"
                        [isStatusUpdateProcessing]="isStatusUpdateProcessing$ | ngrxPush"
                        (statusUpdate)="updateStatus($event, report)"
                    ></uav-id-shared-lib-report-preview-details>
                </div>
            </div>

            <uav-id-shared-lib-report-preview-map
                [report]="report"
                [flights]="vm.reportSummary?.flights"
                [remoteIds]="vm.reportSummary?.remoteIds"
                [hasExtendedDetails]="true"
                [zonesLayerConfig]="REPORT_GEO_ZONES_LAYER_CONFIG"
                [isZonesInfoProcessing]="isSelectedReportGeoZonesInfoLoading$ | ngrxPush"
                [zonesInfo]="selectedReportGeoZonesInfo$ | ngrxPush"
                [zonesError]="selectedReportGeoZonesInfoError$ | ngrxPush"
                (zonesSelect)="getSelectedZonesInfo($event)"
                (zoneDetailsOpen)="openZoneDetailsDialog($event)"
            ></uav-id-shared-lib-report-preview-map>
        </section>
    </ng-container>

    <ng-template #errorMessageTemplate>
        <dtm-ui-error [errorMessage]="'uavIdClientLibMyInterventions.myInterventionPreview.unknownErrorMessage' | transloco"></dtm-ui-error>
    </ng-template>
</ng-container>
