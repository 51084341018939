<ion-app>
    <uav-id-client-menu (cookiesInfoOpen)="openCookiesInfo()"></uav-id-client-menu>

    <uav-id-client-header></uav-id-client-header>

    <ion-content id="main">
        <ion-router-outlet></ion-router-outlet>
        <dtm-ui-cookies-popup
            *ngIf="!isMobileApp && isCookiesInfoOpen$ | ngrxPush"
            (cookiesHide)="closeCookiesInfo()"
        ></dtm-ui-cookies-popup>
    </ion-content>

    <dtm-ui-webpage-footer
        *ngIf="isDesktop$ | ngrxPush"
        [activeLanguage]="activeLanguage$ | ngrxPush"
        (cookiesInfoSelect)="openCookiesInfo()"
    ></dtm-ui-webpage-footer>
</ion-app>
