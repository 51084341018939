import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from "@angular/animations";

export const PANEL_SLIDE_IN_OUT_ANIMATION: AnimationTriggerMetadata[] = [
    trigger("panelSlideInOut", [
        state(
            "true",
            style({
                overflow: "hidden",
                height: "100%",
            })
        ),
        state(
            "false",
            style({
                overflow: "hidden",
                height: "44px",
            })
        ),
        transition("true <=> false", animate("300ms ease-in-out")),
    ]),
];

export const RESULT_ITEM_SLIDE_IN_ANIMATION: AnimationTriggerMetadata[] = [
    trigger("resultSlideIn", [
        transition(":enter", [
            style({ overflow: "hidden", height: "0", paddingTop: "0", paddingBottom: "0", opacity: "0" }),
            animate("300ms ease-in-out", style({ height: "*", paddingTop: "*", paddingBottom: "*" })),
            animate("100ms", style({ opacity: "100%" })),
        ]),
    ]),
];
