import { RegistrationFormData, UpdatePasswordData } from "../authorization.models";
import { RegistrationVerificationRequestPayload } from "../services/authorization-api.converters";

export namespace AuthorizationActions {
    export class Register {
        public static readonly type = "[Authorization] Register";
        constructor(public registrationFormData: RegistrationFormData) {}
    }

    export class VerifyRegistration {
        public static readonly type = "[Authorization] Verify registration";
        constructor(public registrationId: string, public requestPayload: RegistrationVerificationRequestPayload) {}
    }

    export class ResendRegistrationVerificationCode {
        public static readonly type = "[Authorization] Resend registration verification code";
        constructor(public registrationId: string) {}
    }

    export class GetOfficerInstitutions {
        public static readonly type = "[Authorization] Get officer institutions";
    }

    export class GetOfficerUnits {
        public static readonly type = "[Authorization] Get officer units";
        constructor(public institutionId: string) {}
    }

    export class ResetPassword {
        public static readonly type = "[Authorization] Reset password";
        constructor(public email: string) {}
    }

    export class ChangePassword {
        public static readonly type = "[Authorization] Change password";
    }

    export class UpdatePassword {
        public static readonly type = "[Authorization] Update password";
        constructor(public data: UpdatePasswordData) {}
    }

    export class ResendUpdatePasswordVerificationCode {
        public static readonly type = "[Authorization] Resend update password verification code";
    }

    export class RequestAccountDeletion {
        public static readonly type = "[Authorization] Request account deletion";
    }

    export class ConfirmAccountDeletion {
        public static readonly type = "[Authorization] Confirm account deletion";
        constructor(public verificationCode: string) {}
    }

    export class ResendAccountDeletionVerificationCode {
        public static readonly type = "[Authorization] Resend account deletion verification code";
    }
}
