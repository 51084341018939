<div *ngrxLet="isMobile$ as isMobile" class="grid">
    <button type="button" class="button-tertiary back-button" (click)="goBack()">
        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>{{ "uavIdClientLibHelp.help.goBackButtonLabel" | transloco }}
    </button>
    <div class="container">
        <nav class="card navigation" [class.hidden]="isMobile && routerOutlet.isActivated">
            <a *ngFor="let faqUrl of faqUrls" [routerLink]="['/help', faqUrl]" routerLinkActive="active" class="link">{{
                faqUrl | invoke : getFaqSectionHeader | transloco
            }}</a>
        </nav>

        <section class="card content" [class.hidden]="isMobile && !routerOutlet.isActivated">
            <router-outlet #routerOutlet="outlet"></router-outlet>
        </section>
    </div>
</div>
