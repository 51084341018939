<h2>{{ "uavIdClientLibHelp.howToReport.header" | transloco }}</h2>
<div class="row-container">
    <div class="column-content content-with-image">
        <img ngSrc="assets/images/help-globe.svg" alt="" [height]="IMG_HEIGHT_PX" [width]="IMG_WIDTH_PX" />
        <div>
            <h3>{{ "uavIdClientLibHelp.howToReport.stepLabel" | transloco : { step: 1 } }}</h3>
            <p *ngIf="isLoggedIn$ | ngrxPush; else notLoggedInFirstStepDescriptionTemplate">
                {{ "uavIdClientLibHelp.howToReport.firstParagraphNotLoggedIn" | transloco }}
            </p>
            <ng-template #notLoggedInFirstStepDescriptionTemplate>
                <p>{{ "uavIdClientLibHelp.howToReport.firstParagraphLoggedIn" | transloco }}</p>
            </ng-template>
        </div>
    </div>

    <div class="column-content content-with-image">
        <img ngSrc="assets/images/help-binoculars.svg" alt="" [height]="IMG_HEIGHT_PX" [width]="IMG_WIDTH_PX" />
        <div>
            <h3>{{ "uavIdClientLibHelp.howToReport.stepLabel" | transloco : { step: 2 } }}</h3>
            <p>{{ "uavIdClientLibHelp.howToReport.secondParagraph" | transloco }}</p>
        </div>
    </div>

    <div class="column-content content-with-image">
        <img ngSrc="assets/images/help-call112.svg" alt="" [height]="IMG_HEIGHT_PX" [width]="IMG_WIDTH_PX" />
        <div>
            <h3>{{ "uavIdClientLibHelp.howToReport.stepLabel" | transloco : { step: 3 } }}</h3>
            <p>
                <span> {{ "uavIdClientLibHelp.howToReport.thirdParagraph" | transloco }}</span>
                <a routerLink="/help/call-emergency-number" class="link">{{
                    "uavIdClientLibHelp.callEmergencyNumber.header" | transloco
                }}</a>
            </p>
        </div>
    </div>
</div>
