<dtm-map-leaflet-map *ngrxLet="userPosition$ as userPosition">
    <dtm-map-leaflet-user-position [userPosition]="userPosition" [isMarkerInteractive]="false"></dtm-map-leaflet-user-position>

    <div topRightControls>
        <button
            type="button"
            class="leaflet-button"
            routerLink="/help"
            [matTooltip]="'uavIdClientLibReport.reportGeolocation.helpButtonLabel' | transloco"
        >
            <dtm-ui-icon name="question"></dtm-ui-icon>
        </button>
    </div>

    <dtm-map-leaflet-map-layer
        *ngIf="policeHeadquartersLayerConfig$ | ngrxPush as layerConfig"
        [layerConfig]="layerConfig"
    ></dtm-map-leaflet-map-layer>
    <uav-id-shared-lib-leaflet-flights-layer
        [areDetailsShown]="false"
        [flights]="flights$ | ngrxPush"
    ></uav-id-shared-lib-leaflet-flights-layer>
    <uav-id-shared-lib-report-uav-area
        [position]="userPosition"
        [userPosition]="userPosition"
        [uavReportRangeInMeters]="UAV_REPORT_RANGE_IN_METERS"
        (uavPositionUpdate)="uavPositionUpdate.emit($event)"
        (uavOutOfRange)="uavOutOfRange.emit($event)"
    ></uav-id-shared-lib-report-uav-area>
</dtm-map-leaflet-map>

<div class="container container-card info-panel-desktop ion-hide-sm-down">
    <div class="container-content scroll-shadows">
        <ng-container [ngTemplateOutlet]="infoHeader"></ng-container>
        <p>{{ "uavIdClientLibReport.reportFormWizardUavGeolocationStep.descriptionLabel" | transloco }}</p>
    </div>

    <div class="container-actions">
        <ng-container [ngTemplateOutlet]="navigationButtonsTemplate"></ng-container>
    </div>
</div>

<section *ngrxLet="isInfoPanelExpanded$ as isInfoPanelExpanded" class="info-panel ion-hide-sm-up">
    <div>
        <button type="button" (click)="toggleInfoPanel()">
            <ng-container [ngTemplateOutlet]="infoHeader"></ng-container>

            <dtm-ui-icon *ngIf="isInfoPanelExpanded" name="arrow-down"></dtm-ui-icon>
            <dtm-ui-icon *ngIf="!isInfoPanelExpanded" name="arrow-up"></dtm-ui-icon>
        </button>

        <p *ngIf="isInfoPanelExpanded" class="content">
            {{ "uavIdClientLibReport.reportFormWizardUavGeolocationStep.descriptionLabel" | transloco }}
        </p>
    </div>
</section>
<div class="container-actions ion-hide-sm-up">
    <ng-container *ngTemplateOutlet="navigationButtonsTemplate"></ng-container>
</div>

<ng-template #infoHeader>
    <section class="info-header">
        <span class="step-label">{{
            "uavIdClientLibReport.reportFormWizardNavigation.stepNumberLabel" | transloco : { stepNumber: 1, stepsNumber: 3 }
        }}</span>
        <h2>{{ "uavIdClientLibReport.reportFormWizardUavGeolocationStep.headerLabel" | transloco }}</h2>
    </section>
</ng-template>

<ng-template #navigationButtonsTemplate>
    <button class="button-secondary" type="button" (click)="previous.emit()">
        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
        {{ "uavIdClientLibReport.reportFormWizardNavigation.goToPreviousStepButtonLabel" | transloco }}
    </button>
    <button class="button-primary" type="button" (click)="next.emit()">
        {{ "uavIdClientLibReport.reportFormWizardNavigation.goToNextStepButtonLabel" | transloco }}
    </button>
</ng-template>
