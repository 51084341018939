<div class="container">
    <dtm-ui-loader-container
        *ngrxLet="{
            isProcessing: isProcessing$,
            userDataError: userDataError$,
            userData: userData$,
            hasCivilianRole: hasCivilianRole$
        } as vm"
        [isShown]="vm.isProcessing"
    >
        <dtm-ui-error *ngIf="vm.userDataError; else userProfileTemplate" (reload)="reload()"></dtm-ui-error>

        <ng-template #userProfileTemplate>
            <ion-card>
                <div class="card-header">
                    <h2>{{ "uavIdClientLibUserProfile.personalData.sectionTitle" | transloco }}</h2>
                    <button *ngIf="vm.hasCivilianRole" type="button" class="button-secondary" (click)="deleteAccount()">
                        {{ "uavIdClientLibUserProfile.personalData.deleteAccountButtonLabel" | transloco }}
                    </button>
                </div>

                <section *ngIf="!vm.isProcessing" class="data-container">
                    <dtm-ui-label-value
                        [label]="'uavIdClientLibUserProfile.personalData.firstNameLabel' | transloco"
                        [value]="vm.userData?.firstName"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'uavIdClientLibUserProfile.personalData.lastNameLabel' | transloco"
                        [value]="vm.userData?.lastName"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        *ngIf="vm.userData?.type !== UserType.Civilian"
                        [label]="'uavIdClientLibUserProfile.personalData.roleLabel' | transloco"
                        [value]="'uavIdClientLibUserProfile.personalData.roleValueLabel' | transloco : { value: vm.userData?.type }"
                    ></dtm-ui-label-value>
                    <dtm-ui-label-value
                        *ngIf="vm.userData?.officerUnit"
                        [label]="'uavIdClientLibUserProfile.personalData.officerUnitLabel' | transloco"
                        [value]="vm.userData?.officerUnit?.name"
                    ></dtm-ui-label-value>
                </section>
            </ion-card>

            <ion-card>
                <h2>{{ "uavIdClientLibUserProfile.contact.sectionTitle" | transloco }}</h2>

                <ng-container *ngIf="!vm.isProcessing">
                    <dtm-ui-edit-email-address
                        *ngIf="vm.hasCivilianRole; else plainEmailTemplate"
                        [email]="vm.userData?.email"
                        [isRequestedEmailChange]="isEmailUpdateRequested$ | ngrxPush"
                        [hasEmailAddressConflictError]="hasEmailConflictError$ | ngrxPush"
                        (requestDataChange)="updateUserProfileData($event)"
                        (closePanel)="clearEmailLocalState()"
                    ></dtm-ui-edit-email-address>

                    <ng-template #plainEmailTemplate>
                        <dtm-ui-label-value
                            class="plain-email"
                            [label]="'uavIdClientLibUserProfile.contact.emailLabel' | transloco"
                            [value]="vm.userData?.email"
                        >
                        </dtm-ui-label-value>
                    </ng-template>

                    <dtm-ui-edit-phone-number
                        [phoneNumber]="vm.userData?.phoneNumber"
                        [isRequestedPhoneChange]="isPhoneNumberUpdateRequested$ | ngrxPush"
                        [hasPhoneNumberConflictError]="hasPhoneNumberConflictError$ | ngrxPush"
                        (requestDataChange)="updateUserProfileData($event)"
                        (closePanel)="clearPhoneNumberLocalState()"
                    ></dtm-ui-edit-phone-number>
                </ng-container>
            </ion-card>

            <ion-card>
                <div class="card-header">
                    <h2>{{ "uavIdClientLibUserProfile.passwordChange.sectionTitle" | transloco }}</h2>
                    <button type="button" class="button-secondary" (click)="changePassword()">
                        {{ "uavIdClientLibUserProfile.passwordChange.changePasswordButtonLabel" | transloco }}
                    </button>
                </div>

                <p>{{ "uavIdClientLibUserProfile.passwordChange.changePasswordDescriptionMessage" | transloco }}</p>
            </ion-card>
        </ng-template>
    </dtm-ui-loader-container>
</div>
