<ng-container *ngrxLet="statuses$; let statuses">
    <uav-id-client-lib-my-reports-list-filters
        class="grid"
        [statuses]="statuses"
        [sort]="listSort$ | ngrxPush"
        [initialFilters]="initialFilters"
        (filtersChange)="applyFilters($event)"
        (sortChange)="sortList($event, true)"
    ></uav-id-client-lib-my-reports-list-filters>
    <uav-id-client-lib-my-reports-list
        class="grid"
        [reports]="reports$ | ngrxPush"
        [reportsPage]="reportsPage$ | ngrxPush"
        [isProcessing]="isProcessing$ | ngrxPush"
        [hasGetListErrorOccurred]="hasGetListErrorOccurred$ | ngrxPush"
        [sort]="listSort$ | ngrxPush"
        (pageChange)="changePage($event)"
        (sortChange)="sortList($event)"
        (pageReload)="getMyReportsList()"
    ></uav-id-client-lib-my-reports-list>
</ng-container>
