import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Toast } from "ngx-toastr";

@Component({
    selector: "uav-id-client-lib-flights-not-available-toast",
    templateUrl: "flights-not-available-toast.component.html",
    styleUrls: ["../report-geolocation-toast.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightsNotAvailableToastComponent extends Toast {}
