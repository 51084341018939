import { HttpStatusCode } from "@angular/common/http";
import { UserProfileError, UserProfileErrorType } from "../models/user-profile.models";

export interface UpdateRequestResponseBody {
    communicationChannelUpdateRequestId: string;
}

export interface ErrorResponseBody {
    status: HttpStatusCode;
    fieldErrors: { fieldName: string; code: string }[];
}

export function convertErrorResponseBodyToUserProfileError(errorResponseBody: ErrorResponseBody): UserProfileError {
    switch (errorResponseBody.status) {
        case HttpStatusCode.NotAcceptable:
            return { type: UserProfileErrorType.ExpiredCode };
        case HttpStatusCode.Conflict:
            return { type: UserProfileErrorType.AlreadyExists };
        case HttpStatusCode.BadRequest:
            return { type: UserProfileErrorType.InvalidCode };
        default:
            return { type: UserProfileErrorType.Unknown };
    }
}
