export enum FaqUrl {
    WhenToReport = "when-to-report",
    HowToReport = "how-to-report",
    CallEmergencyNumber = "call-emergency-number",
    ApplicationPurpose = "application-purpose",
    DataSource = "data-source",
    RemoteIdOrigin = "remote-id-origin",
    RemoteIdDataMissing = "remote-id-data-missing",
    ReportProcess = "report-process",
    ReportWithoutCall = "report-without-call",
    ReportStatuses = "report-statuses",
    PhoneNumberVerification = "phone-number-verification",
}
