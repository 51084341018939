import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { GeocodingResult } from "../../../../services/geocoding-api.converters";

interface GeocodingResultComponentState {
    result: Partial<GeocodingResult> | undefined;
}

@Component({
    selector: "uav-id-client-lib-geocoding-result[result][iconName]",
    templateUrl: "geocoding-result.component.html",
    styleUrls: ["geocoding-result.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class GeocodingResultComponent {
    @Input() public set result(value: Partial<GeocodingResult>) {
        this.localStore.patchState({ result: value });
    }
    @Input() public iconName: IconName = "map-pin";

    @Output() public resultSelect = new EventEmitter<Partial<GeocodingResult>>();

    public readonly item$ = this.localStore.selectByKey("result");

    @HostListener("click")
    public onResultClick(): void {
        this.resultSelect.emit(this.localStore.selectSnapshotByKey("result"));
    }

    constructor(private readonly localStore: LocalComponentStore<GeocodingResultComponentState>) {
        this.localStore.setState({
            result: undefined,
        });
    }
}
