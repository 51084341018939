import { Inject, Injectable } from "@angular/core";
import { LOCAL_STORAGE } from "@dtm-frontend/shared/utils";

const UAV_ID_GEOCODING_RECENT_SEARCH_KEY = "UAV_ID_GEOCODING_RECENT_SEARCH";
const MAX_NUMBER_OF_SAVED_RECENT_SEARCH_ENTRIES = 5;

@Injectable()
export class GeocodingRecentSearchService {
    constructor(@Inject(LOCAL_STORAGE) private readonly localStorage: Storage) {}

    public getRecentSearches(): string[] {
        const results = this.localStorage.getItem(UAV_ID_GEOCODING_RECENT_SEARCH_KEY);

        if (!results) {
            return [];
        }

        return results.split(",");
    }

    public addRecentSearch(searchQuery: string): string[] {
        const recentSearches = this.getRecentSearches().filter(
            (value) => value?.toLowerCase()?.trim() !== searchQuery?.toLowerCase()?.trim()
        );

        recentSearches.unshift(searchQuery);
        recentSearches.splice(MAX_NUMBER_OF_SAVED_RECENT_SEARCH_ENTRIES);
        this.localStorage.setItem(UAV_ID_GEOCODING_RECENT_SEARCH_KEY, recentSearches.toString());

        return recentSearches;
    }
}
