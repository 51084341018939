import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Position } from "@dtm-frontend/shared/map";
import { LeafletMapLayerConfig } from "@dtm-frontend/shared/map/leaflet";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Flight } from "@dtm-frontend/uav-identification-shared-lib/flight";

interface ReportStepUavGeolocationComponentState {
    userPosition: Position | undefined;
    flights: Flight[];
    isInfoPanelExpanded: boolean;
    policeHeadquartersLayerConfig: LeafletMapLayerConfig | undefined;
}

const UAV_REPORT_RANGE_IN_METERS = 2000;

@Component({
    selector: "uav-id-client-lib-report-step-uav-geolocation[userPosition][flights]",
    templateUrl: "report-step-uav-geolocation.component.html",
    styleUrls: ["../report-steps-shared.component.scss", "report-step-uav-geolocation.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInAnimation()],
    providers: [LocalComponentStore],
})
export class ReportStepUavGeolocationComponent {
    @Input() public set userPosition(value: Position | undefined) {
        this.localStore.patchState({ userPosition: value });
    }
    @Input() public set flights(value: Flight[] | undefined) {
        this.localStore.patchState({ flights: value });
    }
    @Input() public set policeHeadquartersLayerConfig(value: LeafletMapLayerConfig | undefined) {
        this.localStore.patchState({ policeHeadquartersLayerConfig: value });
    }

    @Output() protected readonly next = new EventEmitter<void>();
    @Output() protected readonly previous = new EventEmitter<void>();
    @Output() protected readonly uavPositionUpdate = new EventEmitter<Position>();
    @Output() protected readonly uavOutOfRange = new EventEmitter<number>();

    protected readonly UAV_REPORT_RANGE_IN_METERS = UAV_REPORT_RANGE_IN_METERS;

    protected readonly userPosition$ = this.localStore.selectByKey("userPosition");
    protected readonly flights$ = this.localStore.selectByKey("flights");
    protected readonly isInfoPanelExpanded$ = this.localStore.selectByKey("isInfoPanelExpanded");
    protected readonly policeHeadquartersLayerConfig$ = this.localStore.selectByKey("policeHeadquartersLayerConfig");

    constructor(private readonly localStore: LocalComponentStore<ReportStepUavGeolocationComponentState>) {
        this.localStore.setState({
            userPosition: undefined,
            flights: [],
            isInfoPanelExpanded: true,
            policeHeadquartersLayerConfig: undefined,
        });
    }

    protected toggleInfoPanel(): void {
        this.localStore.patchState(({ isInfoPanelExpanded }) => ({ isInfoPanelExpanded: !isInfoPanelExpanded }));
    }
}
