<div class="container">
    <div class="logo ion-hide-md-down">
        <img src="/assets/images/auth-logo.svg" />
    </div>
    <div class="form-container">
        <dtm-ui-loader-container *ngrxLet="isProcessing$ as isProcessing" [isShown]="isProcessing">
            <div class="form-card">
                <h2 class="header">{{ "uavIdClientLibAuth.resetPassword.header" | transloco }}</h2>

                <ng-container *ngIf="isOnEmailStep$ | ngrxPush; else updatePasswordTemplate">
                    <div class="description">{{ "uavIdClientLibAuth.resetPassword.description" | transloco }}</div>

                    <form [formGroup]="resetPasswordForm" (ngSubmit)="sendVerificationCode()">
                        <dtm-ui-input-field>
                            <label>{{ "uavIdClientLibAuth.resetPassword.emailLabel" | transloco }}</label>
                            <input matInput type="email" [formControl]="resetPasswordForm.controls.email" />
                            <div class="field-error" *dtmUiFieldHasError="resetPasswordForm.controls.email; name: 'required'">
                                {{ "uavIdClientLibAuth.resetPassword.fieldRequiredError" | transloco }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="resetPasswordForm.controls.email; name: 'email'">
                                {{ "uavIdClientLibAuth.resetPassword.emailInvalidError" | transloco }}
                            </div>
                        </dtm-ui-input-field>

                        <button type="submit" class="button-primary">
                            {{ "uavIdClientLibAuth.resetPassword.sendCodeButtonLabel" | transloco }}
                        </button>
                    </form>
                </ng-container>

                <button type="button" class="button-secondary back-button" (click)="goToLoginPage()">
                    {{ "uavIdClientLibAuth.resetPassword.goBackToLoginPageButtonLabel" | transloco }}
                </button>
            </div>
        </dtm-ui-loader-container>
    </div>
</div>

<ng-template #updatePasswordTemplate>
    <uav-id-client-lib-update-password
        (passwordUpdate)="updatePassword($event)"
        (codeResend)="resendVerificationCode()"
    ></uav-id-client-lib-update-password>
</ng-template>
