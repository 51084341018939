import { HttpStatusCode } from "@angular/common/http";
import { OfficerInstitutionEntity, OfficerUnitEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import {
    CodeVerificationError,
    CodeVerificationErrorType,
    RegistrationError,
    RegistrationErrorType,
    RegistrationFormData,
    ResetPasswordError,
    ResetPasswordErrorType,
} from "../authorization.models";

export interface RegistrationRequestPayload {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: {
        countryCode: string;
        number: string;
    };
    languageTag: string;
    officerUnit: OfficerUnitEntity;
}

export interface RegistrationResponseBody {
    id: string;
}

export interface RegistrationErrorResponseBody {
    error: {
        fieldErrors: {
            fieldName: "Email" | "PhoneNumber";
            code: "AlreadyUsed" | "MobilePhoneNumber" | "Email";
        }[];
    };
}

export interface CodeVerificationErrorResponseBody {
    error: {
        status: HttpStatusCode;
        fieldErrors: {
            fieldName: "ConfirmationCode";
            code: "Incorrect";
        }[];
    };
}

export interface RegistrationVerificationRequestPayload {
    code: string;
    password: string;
}

export type GetOfficerInstitutionsResponseBody = OfficerInstitutionEntity[];
export type GetOfficerUnitsResponseBody = OfficerUnitEntity[];

export interface AccountDeletionResponseBody {
    deleteAccountRequestId: string;
}

export interface ModifyPasswordResponseBody {
    passwordModificationRequestId: string;
}

interface ResetPasswordErrorResponseBody {
    error: {
        status: HttpStatusCode;
    };
}

// TODO: Update once language change is implemented - IB-143
const LANGUAGE_TAG = "pl-PL";

export function convertRegistrationFormDataToRegistrationRequestPayload(
    registrationFormData: RegistrationFormData
): RegistrationRequestPayload {
    const { firstName, lastName, email, phoneNumber, officerUnit } = registrationFormData ?? {};

    return {
        firstName,
        lastName,
        email,
        phoneNumber,
        languageTag: LANGUAGE_TAG,
        officerUnit,
    };
}

export function convertRegistrationErrorResponseBodyToRegistrationError(errorResponse: RegistrationErrorResponseBody): RegistrationError {
    const fieldErrors = errorResponse?.error?.fieldErrors;

    if (!fieldErrors) {
        return { type: RegistrationErrorType.Unknown };
    }

    const emailErrorCode = fieldErrors.find((fieldError) => fieldError.fieldName === "Email")?.code;

    if (emailErrorCode) {
        return { type: emailErrorCode === "AlreadyUsed" ? RegistrationErrorType.EmailAlreadyExists : RegistrationErrorType.EmailInvalid };
    }

    const phoneNumberErrorCode = fieldErrors.find((fieldError) => fieldError.fieldName === "PhoneNumber")?.code;

    if (phoneNumberErrorCode) {
        return {
            type:
                phoneNumberErrorCode === "AlreadyUsed"
                    ? RegistrationErrorType.PhoneNumberAlreadyExists
                    : RegistrationErrorType.PhoneNumberInvalid,
        };
    }

    return { type: RegistrationErrorType.Unknown };
}

export function convertCodeVerificationErrorResponseBodyToCodeVerificationError(
    errorResponse: CodeVerificationErrorResponseBody
): CodeVerificationError {
    const status = errorResponse?.error?.status;
    if (status === HttpStatusCode.NotFound) {
        return { type: CodeVerificationErrorType.CodeExpired };
    }

    const fieldErrors = errorResponse?.error?.fieldErrors;
    const isInvalidCode = fieldErrors?.find((fieldError) => fieldError.fieldName === "ConfirmationCode")?.code === "Incorrect";

    if (isInvalidCode) {
        return { type: CodeVerificationErrorType.InvalidCode };
    }

    return { type: CodeVerificationErrorType.Unknown };
}

export function convertCodeVerificationErrorResponseBodyToCodeVerificationErrorForResend(
    errorResponse: CodeVerificationErrorResponseBody
): CodeVerificationError {
    const status = errorResponse?.error?.status;
    if (status === HttpStatusCode.NotFound) {
        return { type: CodeVerificationErrorType.CodeExpired };
    }

    return { type: CodeVerificationErrorType.CodeResendFailed };
}

export function convertResetPasswordErrorResponseBodyToResetPasswordError(
    errorResponse: ResetPasswordErrorResponseBody
): ResetPasswordError {
    if (errorResponse?.error?.status === HttpStatusCode.NotFound) {
        return { type: ResetPasswordErrorType.AccountNotFound };
    }

    return { type: ResetPasswordErrorType.Unknown };
}
