<uav-id-client-lib-card-template [title]="'uavIdClientLibAuth.registration.headerLabel' | transloco">
    <form autocomplete="off" [formGroup]="registrationForm" (submit)="submit()">
        <dtm-ui-input-field class="form-item">
            <label>{{ "uavIdClientLibAuth.registration.firstNameLabel" | transloco }}</label>
            <input matInput type="text" [formControl]="firstNameControl" />
            <div class="field-error" *dtmUiFieldHasError="firstNameControl; name: ['required', 'pattern']">
                {{ "uavIdClientLibAuth.registration.fieldRequiredError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="firstNameControl; name: 'maxlength'; error as error">
                {{ "uavIdClientLibAuth.registration.fieldMaxLengthError" | transloco : { maxLength: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field class="form-item">
            <label>{{ "uavIdClientLibAuth.registration.lastNameLabel" | transloco }}</label>
            <input matInput type="text" [formControl]="lastNameControl" />
            <div class="field-error" *dtmUiFieldHasError="lastNameControl; name: ['required', 'pattern']">
                {{ "uavIdClientLibAuth.registration.fieldRequiredError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="lastNameControl; name: 'maxlength'; error as error">
                {{ "uavIdClientLibAuth.registration.fieldMaxLengthError" | transloco : { maxLength: error.requiredLength } }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field class="form-item">
            <label>{{ "uavIdClientLibAuth.registration.emailLabel" | transloco }}</label>
            <input matInput type="email" [formControl]="emailControl" />
            <div class="field-error" *dtmUiFieldHasError="emailControl; name: 'required'">
                {{ "uavIdClientLibAuth.registration.fieldRequiredError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="emailControl; name: 'email'">
                {{ "uavIdClientLibAuth.registration.emailInvalidError" | transloco }}
            </div>
        </dtm-ui-input-field>

        <div *ngIf="officerUnitControl.enabled" @slideIn>
            <dtm-ui-info-message>
                <p>{{ "uavIdClientLibAuth.registration.officerAccountInfoMessage" | transloco }}</p>
            </dtm-ui-info-message>

            <dtm-ui-loader-container [isShown]="areOfficerUnitsProcessing$ | ngrxPush">
                <dtm-ui-select-field class="form-item" [formControl]="officerUnitControl" [compareWith]="compareOfficerUnits">
                    <label>{{ "uavIdClientLibAuth.registration.officerUnitLabel" | transloco }}</label>
                    <dtm-ui-select-option *ngFor="let officerUnit of officerUnits$ | ngrxPush" [value]="officerUnit">
                        {{ officerUnit.name }}
                    </dtm-ui-select-option>
                    <div class="field-error" *dtmUiFieldHasError="officerUnitControl; name: 'required'">
                        {{ "uavIdClientLibAuth.registration.fieldRequiredError" | transloco }}
                    </div>
                </dtm-ui-select-field>
            </dtm-ui-loader-container>
        </div>

        <dtm-ui-phone-number-field class="form-item" [formControl]="phoneNumberControl">
            <div *dtmUiFieldHasError="phoneNumberControl; name: 'required'" class="field-error">
                {{ "uavIdClientLibAuth.registration.fieldRequiredError" | transloco }}
            </div>
            <div *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumber'" class="field-error">
                {{ "uavIdClientLibAuth.registration.phoneNumberInvalidError" | transloco }}
            </div>
            <div *dtmUiFieldHasError="phoneNumberControl; name: 'invalidNumberType'" class="field-error">
                {{ "uavIdClientLibAuth.registration.phoneNumberInvalidNumberTypeError" | transloco }}
            </div>
        </dtm-ui-phone-number-field>

        <dtm-ui-input-field class="form-item" [isClearable]="false">
            <label>{{ "uavIdClientLibAuth.registration.passwordLabel" | transloco }}</label>
            <input #password matInput type="password" [formControl]="passwordControl" />
            <dtm-ui-password-visibility-switch [inputParent]="password" class="field-suffix"> </dtm-ui-password-visibility-switch>
            <div class="field-hint">
                {{ "uavIdClientLibAuth.passwordComplexityHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="passwordControl; name: 'required'">
                {{ "uavIdClientLibAuth.registration.fieldRequiredError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="passwordControl; name: 'pattern'">
                {{ "uavIdClientLibAuth.passwordComplexityHint" | transloco }}
            </div>
        </dtm-ui-input-field>

        <div class="form-item">
            <dtm-ui-checkbox-field class="checkbox-with-link" [formControl]="areTermsOfUseAcceptedControl">
                <span>{{ "uavIdClientLibAuth.registration.acceptLabel" | transloco }}</span>
                <a
                    [href]="termsOfUseUrl"
                    rel="noopener noreferrer"
                    target="_blank"
                    class="button-link custom"
                    (click)="$event.stopPropagation()"
                    >{{ "uavIdClientLibAuth.registration.termsOfUseLabel" | transloco }}</a
                >
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors class="preserve-error-space">
                <div *dtmUiFieldHasError="areTermsOfUseAcceptedControl; name: 'required'">
                    {{ "uavIdClientLibAuth.registration.fieldRequiredError" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </div>

        <div class="form-item">
            <dtm-ui-checkbox-field [formControl]="isDataConfirmedControl">
                {{ "uavIdClientLibAuth.registration.confirmDataMessage" | transloco }}
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors class="preserve-error-space">
                <div *dtmUiFieldHasError="isDataConfirmedControl; name: 'required'">
                    {{ "uavIdClientLibAuth.registration.fieldRequiredError" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </div>

        <button class="button-primary" type="submit">
            {{ "uavIdClientLibAuth.registration.goToNextStepButtonLabel" | transloco }}
        </button>
    </form>
</uav-id-client-lib-card-template>
