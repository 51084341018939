<div class="container container-card">
    <div class="container-content scroll-shadows">
        <img ngSrc="assets/images/report-call112.svg" alt="" [width]="IMG_WIDTH" [height]="IMG_HEIGHT" />
        <div>
            <span class="step-label">
                {{
                    "uavIdClientLibReport.reportFormWizardNavigation.stepNumberLabel" | transloco : { stepNumber: 3, stepsNumber: 3 }
                }}</span
            >
            <h2>{{ "uavIdClientLibReport.reportFormWizardSummaryStep.headerLabel" | transloco }}</h2>
            <p>{{ "uavIdClientLibReport.reportFormWizardSummaryStep.emergencyCallDescriptionLabel" | transloco }}</p>
            <div class="report-number-container">
                <span>{{ "uavIdClientLibReport.reportFormWizardSummaryStep.reportNumberLabel" | transloco }}</span>
                <div>{{ reportNumber$ | ngrxPush }}</div>
            </div>
            <mat-divider></mat-divider>
            <p>{{ "uavIdClientLibReport.reportFormWizardSummaryStep.confirmDetailsDescriptionLabel" | transloco }}</p>
        </div>
    </div>
    <div class="container-actions">
        <button class="button-secondary" type="button" (click)="cancel()">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
            {{ "uavIdClientLibReport.reportFormWizardSummaryStep.cancelButtonLabel" | transloco }}
        </button>
        <button class="button-primary" type="button" (click)="next.emit()">
            {{ "uavIdClientLibReport.reportFormWizardSummaryStep.callEmergencyButtonLabel" | transloco }}
        </button>
    </div>
</div>
