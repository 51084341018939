<ng-container [ngSwitch]="step$ | ngrxPush">
    <form *ngSwitchCase="UpdateStep.Password" [formGroup]="updatePasswordForm" (ngSubmit)="goToVerificationStep()">
        <dtm-ui-input-field>
            <label>{{ "uavIdClientLibAuth.updatePassword.passwordLabel" | transloco }}</label>
            <input #passwordInput matInput type="password" formControlName="password" />
            <dtm-ui-password-visibility-switch [inputParent]="passwordInput" class="field-suffix"></dtm-ui-password-visibility-switch>
            <div class="field-hint">
                {{ "uavIdClientLibAuth.passwordComplexityHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="updatePasswordForm.controls.password; name: 'requiredTouched'">
                {{ "uavIdClientLibAuth.updatePassword.fieldRequiredError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="updatePasswordForm.controls.password; name: 'pattern'">
                {{ "uavIdClientLibAuth.passwordComplexityHint" | transloco }}
            </div>
        </dtm-ui-input-field>

        <dtm-ui-input-field>
            <label>{{ "uavIdClientLibAuth.updatePassword.passwordRepeatLabel" | transloco }}</label>
            <input #passwordRepeatInput matInput type="password" formControlName="passwordRepeat" />
            <dtm-ui-password-visibility-switch [inputParent]="passwordRepeatInput" class="field-suffix"></dtm-ui-password-visibility-switch>
            <div class="field-error" *dtmUiFieldHasError="updatePasswordForm.controls.passwordRepeat; name: 'requiredTouched'">
                {{ "uavIdClientLibAuth.updatePassword.fieldRequiredError" | transloco }}
            </div>

            <div class="field-error" *dtmUiFieldHasError="updatePasswordForm; name: 'passwordRepeat'">
                {{ "uavIdClientLibAuth.updatePassword.passwordRepeatError" | transloco }}
            </div>
        </dtm-ui-input-field>

        <button type="submit" class="button-primary">
            {{ "uavIdClientLibAuth.updatePassword.goToVerificationStepButtonLabel" | transloco }}
        </button>
    </form>

    <uav-id-client-lib-code-verification-template
        *ngSwitchCase="UpdateStep.Verification"
        (codeResend)="codeResend.emit()"
        (codeVerify)="updatePassword($event)"
    >
        <p>{{ "uavIdClientLibAuth.updatePassword.verificationCodeDescription" | transloco }}</p>
    </uav-id-client-lib-code-verification-template>
</ng-container>
