import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { CodeVerificationError, CodeVerificationErrorType, UpdatePasswordData } from "../../authorization.models";
import { AuthorizationActions } from "../../state/authorization.actions";
import { AuthorizationState } from "../../state/authorization.state";
import { UpdatePasswordComponent } from "../update-password/update-password.component";

const USER_PROFILE_URL = "/user-profile";

@UntilDestroy()
@Component({
    templateUrl: "change-password.component.html",
    styleUrls: ["change-password.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent {
    @ViewChild(UpdatePasswordComponent) private updatePasswordComponent: UpdatePasswordComponent | undefined;

    protected readonly isProcessing$ = this.store.select(AuthorizationState.isUpdatePasswordProcessing);

    constructor(
        private readonly router: Router,
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {}

    public ionViewWillEnter() {
        this.store
            .dispatch(new AuthorizationActions.ChangePassword())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.changePasswordError);

                if (!error) {
                    return;
                }

                this.toastrService.error(this.translocoService.translate("uavIdClientLibAuth.changePassword.unknownError"));
            });
    }

    public ionViewWillLeave() {
        this.updatePasswordComponent?.resetComponentState();
    }

    protected goToUserProfilePage(): void {
        this.router.navigateByUrl(USER_PROFILE_URL);
    }

    protected updatePassword(data: UpdatePasswordData): void {
        this.store
            .dispatch(new AuthorizationActions.UpdatePassword(data))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.updatePasswordVerificationError);

                if (error) {
                    this.handleVerificationError(error);

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("uavIdClientLibAuth.changePassword.updatePasswordSuccessMessage")
                );
                this.router.navigateByUrl(USER_PROFILE_URL);
            });
    }

    protected resendVerificationCode(): void {
        this.store
            .dispatch(new AuthorizationActions.ResendUpdatePasswordVerificationCode())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.updatePasswordVerificationError);

                if (error) {
                    this.handleResendVerificationError(error);

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("uavIdClientLibAuth.changePassword.resendVerificationCodeSuccessMessage")
                );
            });
    }

    private handleVerificationError(error: CodeVerificationError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case CodeVerificationErrorType.InvalidCode: {
                errorMessageKey = "uavIdClientLibAuth.changePassword.invalidCodeError";
                break;
            }
            case CodeVerificationErrorType.CodeExpired: {
                errorMessageKey = "uavIdClientLibAuth.changePassword.codeExpiredError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibAuth.changePassword.codeVerificationUnknownError";
            }
        }

        this.toastrService.error(this.translocoService.translate(errorMessageKey));
    }

    private handleResendVerificationError(error: CodeVerificationError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case CodeVerificationErrorType.CodeExpired: {
                errorMessageKey = "uavIdClientLibAuth.changePassword.codeExpiredError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibAuth.changePassword.codeResendFailedError";
            }
        }

        this.toastrService.error(this.translocoService.translate(errorMessageKey));
    }
}
