import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { OfficerEntity } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Observable, catchError, throwError } from "rxjs";
import { UserProfileErrorType } from "../models/user-profile.models";
import { USER_PROFILE_ENDPOINTS, UserProfileEndpoints } from "../user-profile.tokens";
import { UpdateRequestResponseBody, convertErrorResponseBodyToUserProfileError } from "./user-profile-api.converters";

@Injectable()
export class UserProfileApiService {
    constructor(
        private readonly http: HttpClient,
        @Inject(USER_PROFILE_ENDPOINTS) private readonly userProfileEndpoints: UserProfileEndpoints
    ) {}

    public getUserData(userId: string): Observable<OfficerEntity> {
        return this.http
            .get<OfficerEntity>(StringUtils.replaceInTemplate(this.userProfileEndpoints.getUser, { id: userId }))
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.Unknown }))));
    }

    public requestEmailUpdate(userId: string, email: string): Observable<UpdateRequestResponseBody> {
        return this.http
            .post<UpdateRequestResponseBody>(StringUtils.replaceInTemplate(this.userProfileEndpoints.requestEmailUpdate, { id: userId }), {
                email,
            })
            .pipe(catchError((error) => throwError(() => convertErrorResponseBodyToUserProfileError(error))));
    }

    public updateEmail(userId: string, updateRequestId: string, verificationCode: string): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.userProfileEndpoints.updateEmail, { id: userId, updateRequestId }), {
                code: verificationCode,
            })
            .pipe(catchError((error) => throwError(() => convertErrorResponseBodyToUserProfileError(error))));
    }

    public requestPhoneNumberUpdate(userId: string, phoneNumber: PhoneNumber): Observable<UpdateRequestResponseBody> {
        return this.http
            .post<UpdateRequestResponseBody>(
                StringUtils.replaceInTemplate(this.userProfileEndpoints.requestPhoneNumberUpdate, { id: userId }),
                {
                    phoneNumber,
                }
            )
            .pipe(catchError((error) => throwError(() => convertErrorResponseBodyToUserProfileError(error))));
    }

    public updatePhoneNumber(userId: string, updateRequestId: string, verificationCode: string): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.userProfileEndpoints.updatePhoneNumber, { id: userId, updateRequestId }), {
                code: verificationCode,
            })
            .pipe(catchError((error) => throwError(() => convertErrorResponseBodyToUserProfileError(error))));
    }

    public changePassword(userId: string): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.userProfileEndpoints.changePassword, { id: userId }), {})
            .pipe(catchError(() => throwError(() => ({ type: UserProfileErrorType.Unknown }))));
    }
}
