import { ChangeDetectionStrategy, Component } from "@angular/core";
import { RegistrationService } from "../../services/registration.service";

@Component({
    selector: "uav-id-client-lib-phone-verification",
    templateUrl: "./phone-verification.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneVerificationComponent {
    constructor(private readonly registrationService: RegistrationService) {}

    public resendSmsCode() {
        this.registrationService.resendVerificationCode();
    }

    public verifySmsCode(code: string) {
        this.registrationService.verifyRegistration(code);
    }
}
