import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Observable, catchError, map, throwError } from "rxjs";
import { FLIGHT_ENDPOINTS, FlightEndpoints } from "../flight.tokens";
import { FlightErrorType, FlightEvent, FlightEventMessage } from "../models/flight.models";
import { FlightsResponseBody, convertIMessageToFlightEventMessage } from "./flight-api.converters";

const WS_FLIGHTS_UPDATES_EVENT_TYPES = Object.values(FlightEvent);

@Injectable()
export class FlightApiService {
    constructor(
        @Inject(FLIGHT_ENDPOINTS) private readonly endpoints: FlightEndpoints,
        private readonly http: HttpClient,
        private readonly websocketService: WebsocketService
    ) {}

    public startFlightsUpdatesWatch(): Observable<FlightEventMessage> {
        return this.websocketService
            .watchTopic(this.endpoints.wsFlightsTopic, WS_FLIGHTS_UPDATES_EVENT_TYPES)
            .pipe(map((message) => convertIMessageToFlightEventMessage(message)));
    }

    public getFlights(longitude: number, latitude: number, date: Date = new Date()): Observable<FlightsResponseBody> {
        return this.http
            .get<FlightsResponseBody>(this.endpoints.getFlights, {
                params: {
                    longitude,
                    latitude,
                    referenceDate: date.toISOString(),
                },
            })
            .pipe(catchError(() => throwError(() => ({ type: FlightErrorType.Unknown }))));
    }
}
