export type NominatimGeocodingResponseFormat = "xml" | "json" | "jsonv2" | "geojson" | "geocodejson";

export interface GeocodingApiRequestPayload {
    format: NominatimGeocodingResponseFormat;
    q: string;
    countrycodes?: string;
}

export interface GeocodingApiResponseBody {
    display_name: string;
    lat: number;
    lon: number;
}

export interface GeocodingResult {
    address: string;
    latitude: number;
    longitude: number;
}

export function convertGeocodingApiResponseToGeocodingCoordinates(response: GeocodingApiResponseBody[]): GeocodingResult[] | undefined {
    return response.map((result) => ({
        address: result.display_name,
        latitude: result.lat,
        longitude: result.lon,
    }));
}
