import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngxs/store";
import { map } from "rxjs";
import { RegistrationService } from "../../services/registration.service";
import { AuthorizationState } from "../../state/authorization.state";

@Component({
    selector: "uav-id-client-lib-email-verification",
    templateUrl: "./email-verification.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailVerificationComponent {
    public readonly userEmail$ = this.store.select(AuthorizationState.authorizationData).pipe(map((data) => data?.email));

    constructor(private readonly registrationService: RegistrationService, private readonly store: Store) {}

    public resendEmailCode() {
        this.registrationService.resendVerificationCode();
    }

    public verifyEmailCode(code: string) {
        this.registrationService.verifyRegistration(code);
    }
}
