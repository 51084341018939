<div class="container container-card">
    <div class="container-header">
        <button type="button" class="button-icon" (click)="previous.emit()">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
        </button>
        <h2>{{ "uavIdClientLibReport.reportFormWizardVerificationCodeStep.headerLabel" | transloco }}</h2>
    </div>
    <div class="container-content">
        <uav-id-client-lib-code-verification-template (codeResend)="resendSmsCode()" (codeVerify)="verifySmsCode($event)">
            <p>{{ "uavIdClientLibReport.reportFormWizardVerificationCodeStep.descriptionMessage" | transloco }}</p>
        </uav-id-client-lib-code-verification-template>
    </div>
</div>
