import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface ReportStepThankYouPageComponentState {
    isAuthorized: boolean;
    isIntervention: boolean;
    reportNumber: string | undefined;
}

const MY_REPORTS_URL = "/my-reports";
const MY_INTERVENTIONS_URL = "/my-interventions";
const IMG_WIDTH = 213;
const IMG_HEIGHT = 213;

@Component({
    selector: "uav-id-client-lib-report-step-thank-you-page[isAuthorized][isIntervention][reportNumber]",
    templateUrl: "report-step-thank-you-page.component.html",
    styleUrls: ["../report-steps-shared.component.scss", "report-step-thank-you-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ReportStepThankYouPageComponent {
    @Input() public set isAuthorized(value: BooleanInput) {
        this.localStore.patchState({ isAuthorized: coerceBooleanProperty(value) });
    }
    @Input() public set isIntervention(value: BooleanInput) {
        this.localStore.patchState({ isIntervention: coerceBooleanProperty(value) });
    }
    @Input() public set reportNumber(value: string | undefined) {
        this.localStore.patchState({ reportNumber: value });
    }

    protected readonly MY_REPORTS_URL = MY_REPORTS_URL;
    protected readonly MY_INTERVENTIONS_URL = MY_INTERVENTIONS_URL;
    protected readonly IMG_WIDTH = IMG_WIDTH;
    protected readonly IMG_HEIGHT = IMG_HEIGHT;

    protected readonly isAuthorized$ = this.localStore.selectByKey("isAuthorized");
    protected readonly isIntervention$ = this.localStore.selectByKey("isIntervention");
    protected readonly reportNumber$ = this.localStore.selectByKey("reportNumber");

    constructor(private readonly localStore: LocalComponentStore<ReportStepThankYouPageComponentState>) {
        this.localStore.setState({
            isAuthorized: false,
            isIntervention: false,
            reportNumber: undefined,
        });
    }
}
