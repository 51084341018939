import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { ReportSummaryResolver } from "@dtm-frontend/uav-identification-shared-lib/report";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { MyReportPreviewComponent } from "./components/my-report-preview/my-report-preview.component";
import { MyReportsListContainerComponent } from "./components/my-reports-list-container/my-reports-list-container.component";

const routes: Routes = [
    {
        path: "my-reports",
        component: MyReportsListContainerComponent,
        canActivate: [DtmRoleGuard],
        data: {
            roles: [UavIdRole.Civilian, UavIdRole.Officer, UavIdRole.DutyOfficer],
        },
    },
    {
        path: "my-reports/:id",
        component: MyReportPreviewComponent,
        canActivate: [DtmRoleGuard],
        data: {
            roles: [UavIdRole.Civilian, UavIdRole.Officer, UavIdRole.DutyOfficer],
        },
        resolve: {
            init: ReportSummaryResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    providers: [ReportSummaryResolver],
    exports: [RouterModule],
})
export class MyReportsRoutingModule {}
