import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";

const IMG_HEIGHT_PX = 72;
const IMG_WIDTH_PX = 72;

@Component({
    selector: "uav-id-client-lib-how-to-report",
    templateUrl: "./how-to-report.component.html",
    styleUrls: ["../help.scss", "how-to-report.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HowToReportComponent {
    protected readonly IMG_HEIGHT_PX = IMG_HEIGHT_PX;
    protected readonly IMG_WIDTH_PX = IMG_WIDTH_PX;

    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);

    constructor(private readonly store: Store) {}
}
