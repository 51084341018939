import { BreakpointObserver } from "@angular/cdk/layout";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { DEFAULT_DEBOUNCE_TIME, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs";
import { GeocodingResult } from "../../../services/geocoding-api.converters";
import { GeocodingActions } from "../../../state/geocoding.actions";
import { GeocodingState } from "../../../state/geocoding.state";
import { ReportActions } from "../../../state/report.actions";
import { GeocodingRecentSearchService } from "./geocoding-recent-search.service";
import { PANEL_SLIDE_IN_OUT_ANIMATION, RESULT_ITEM_SLIDE_IN_ANIMATION } from "./geocoding-search-panel-animations";

interface GeocodingSearchPanelComponentState {
    isMobilePanelOpened: boolean;
    recentSearches: Partial<GeocodingResult>[];
}

const DESKTOP_MIN_WIDTH_BREAKPOINT = "(min-width: 1200px)";

@UntilDestroy()
@Component({
    selector: "uav-id-client-lib-geocoding-search-panel",
    templateUrl: "geocoding-search-panel.component.html",
    styleUrls: ["geocoding-search-panel.component.scss"],
    animations: [PANEL_SLIDE_IN_OUT_ANIMATION, RESULT_ITEM_SLIDE_IN_ANIMATION],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class GeocodingSearchPanelComponent {
    public readonly isMobilePanelOpened$ = this.localStore.selectByKey("isMobilePanelOpened");
    public readonly recentSearches$ = this.localStore.selectByKey("recentSearches");
    public readonly results$ = this.store.select(GeocodingState.geocodingResult);
    public readonly isProcessing$ = this.store.select(GeocodingState.isProcessing);
    public readonly isDesktop$ = this.breakpointObserver.observe(DESKTOP_MIN_WIDTH_BREAKPOINT).pipe(
        distinctUntilChanged(),
        map(({ matches }) => {
            if (!matches) {
                this.localStore.patchState({
                    isMobilePanelOpened: !!this.store.selectSnapshot(GeocodingState.geocodingResult)?.length,
                });
            }

            return matches;
        })
    );

    public readonly searchForm: UntypedFormGroup;
    public readonly searchInput = new UntypedFormControl(null);

    constructor(
        private readonly breakpointObserver: BreakpointObserver,
        private readonly geocodingRecentSearchService: GeocodingRecentSearchService,
        private readonly localStore: LocalComponentStore<GeocodingSearchPanelComponentState>,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastService: ToastrService
    ) {
        this.localStore.setState({
            isMobilePanelOpened: false,
            recentSearches: this.mapRecentSearchesToResultList(this.geocodingRecentSearchService.getRecentSearches()),
        });

        this.searchForm = new UntypedFormGroup({
            searchInput: this.searchInput,
        });

        this.onInputClear();
        this.submitOnFormValueChanges();
    }

    public updateUserPosition(result: Partial<GeocodingResult>): void {
        if (result?.latitude === undefined || result?.longitude === undefined) {
            return;
        }

        this.store
            .dispatch(new ReportActions.UpdateUserPosition(result.latitude, result.longitude))
            .pipe(tap(() => this.localStore.patchState({ isMobilePanelOpened: false })))
            .subscribe();
    }

    public lookUpRecentSearch(recentSearch: Partial<GeocodingResult>): void {
        this.searchInput.setValue(recentSearch?.address);
    }

    public toggle(): void {
        this.localStore.patchState(({ isMobilePanelOpened }) => ({
            isMobilePanelOpened: !isMobilePanelOpened,
        }));
    }

    private submitOnFormValueChanges(): void {
        this.searchInput.valueChanges
            .pipe(
                debounceTime(DEFAULT_DEBOUNCE_TIME),
                distinctUntilChanged(),
                RxjsUtils.filterFalsy(),
                switchMap((searchValue: string) => {
                    const value = searchValue?.trim();

                    return this.store.dispatch(new GeocodingActions.GetGeocodes(value)).pipe(
                        tap(() => {
                            const error = this.store.selectSnapshot(GeocodingState.geocodingError);

                            if (error) {
                                this.handleGeocodingError();

                                return;
                            }

                            if (this.store.selectSnapshot(GeocodingState.geocodingResult)?.length) {
                                const recentSearches = this.mapRecentSearchesToResultList(
                                    this.geocodingRecentSearchService.addRecentSearch(value)
                                );

                                this.localStore.patchState({ recentSearches });
                            }
                        })
                    );
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private onInputClear(): void {
        this.searchInput.valueChanges
            .pipe(
                filter((value) => !value),
                tap(() => this.store.dispatch(GeocodingActions.ClearResults)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleGeocodingError(): void {
        const message = this.translocoService.translate("uavIdClientLibReport.geocodingSearch.geocodingGenericErrorLabel");
        this.toastService.error(message);
    }

    private mapRecentSearchesToResultList(recentSearches: string[]): Partial<GeocodingResult>[] {
        return recentSearches?.map((searchQuery) => ({ address: searchQuery }));
    }
}
