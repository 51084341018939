import { InjectionToken } from "@angular/core";

export interface UserProfileEndpoints {
    getUser: string;
    requestEmailUpdate: string;
    updateEmail: string;
    requestPhoneNumberUpdate: string;
    updatePhoneNumber: string;
    changePassword: string;
}

export const USER_PROFILE_ENDPOINTS = new InjectionToken<UserProfileEndpoints>("User profile endpoints");
