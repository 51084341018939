import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, catchError, map, throwError } from "rxjs";
import { ReportFormData, ReportIdentity } from "../models/report.model";
import { REPORT_ENDPOINTS, ReportEndpoints } from "../report.tokens";
import {
    ReportIdentityVerificationRequestPayload,
    ReportIdentityVerificationResponseBody,
    ReportResponseBody,
    convertReportFormDataToReportRequestPayload,
    convertReportIdentityVerificationCodeErrorResponseBodyToReportError,
    convertReportIdentityVerificationErrorResponseBodyToReportError,
    convertReportIdentityVerificationResponseBodyToReportIdentity,
    convertSendReportErrorResponseBodyToReportError,
} from "./report-api.converters";

// TODO: Update once language change is implemented - IB-143
const LANGUAGE_TAG = "pl-PL";

@Injectable()
export class ReportApiService {
    constructor(private readonly http: HttpClient, @Inject(REPORT_ENDPOINTS) private readonly endpoints: ReportEndpoints) {}

    public sendReport(reportForm: ReportFormData): Observable<ReportResponseBody> {
        return this.http
            .post<ReportResponseBody>(this.endpoints.sendReport, convertReportFormDataToReportRequestPayload(reportForm))
            .pipe(catchError((errorResponse) => throwError(() => convertSendReportErrorResponseBodyToReportError(errorResponse))));
    }

    public verifyIdentity(phoneNumber: PhoneNumber): Observable<ReportIdentity> {
        const payload: ReportIdentityVerificationRequestPayload = { phoneNumber, languageTag: LANGUAGE_TAG };

        return this.http.post<ReportIdentityVerificationResponseBody>(this.endpoints.verifyIdentity, payload).pipe(
            map((response) => convertReportIdentityVerificationResponseBodyToReportIdentity(response)),
            catchError((errorResponse) => throwError(() => convertReportIdentityVerificationErrorResponseBodyToReportError(errorResponse)))
        );
    }

    public sendIdentityVerificationCode(verificationId: string, code: string) {
        return this.http
            .post(StringUtils.replaceInTemplate(this.endpoints.sendIdentityVerificationCode, { verificationId }), { code })
            .pipe(
                catchError((errorResponse) =>
                    throwError(() => convertReportIdentityVerificationCodeErrorResponseBodyToReportError(errorResponse))
                )
            );
    }

    public resendIdentityVerificationCode(verificationId: string) {
        return this.http
            .post(StringUtils.replaceInTemplate(this.endpoints.resendIdentityVerificationCode, { verificationId }), {})
            .pipe(
                catchError((errorResponse) =>
                    throwError(() => convertReportIdentityVerificationCodeErrorResponseBodyToReportError(errorResponse))
                )
            );
    }
}
