<div class="container">
    <section *ngIf="title$ | ngrxPush as title" class="title">
        <button *ngIf="isNavigationBackEnabled$ | ngrxPush" type="button" class="button-icon" (click)="goBack()">
            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
        </button>
        <h2>{{ title }}</h2>
    </section>

    <ion-card class="card">
        <ng-content></ng-content>
    </ion-card>
</div>
