<section *ngIf="isDesktop$ | ngrxPush; else mobileTemplate" class="desktop-container">
    <h2>{{ "uavIdClientLibReport.geocodingSearch.headerLabel" | transloco }}</h2>
    <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
</section>

<ng-template #mobileTemplate>
    <section
        *ngrxLet="isMobilePanelOpened$ as isMobilePanelOpened"
        [@panelSlideInOut]="isMobilePanelOpened"
        [ngClass]="{ opened: isMobilePanelOpened }"
        class="mobile-container"
    >
        <button type="button" class="toggle-button" (click)="toggle()">
            <h2>{{ "uavIdClientLibReport.geocodingSearch.headerLabel" | transloco }}</h2>

            <dtm-ui-icon [name]="isMobilePanelOpened ? 'arrow-down' : 'arrow-up'"></dtm-ui-icon>
        </button>

        <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
    </section>
</ng-template>

<ng-template #contentTemplate>
    <div class="content">
        <p>{{ "uavIdClientLibReport.geocodingSearch.markYourPositionDescriptionLabel" | transloco }}</p>

        <form autocomplete="off" [formGroup]="searchForm">
            <dtm-ui-input-field [isClearable]="true">
                <dtm-ui-icon name="search" class="field-prefix"></dtm-ui-icon>
                <input matInput type="text" [formControl]="searchInput" />
            </dtm-ui-input-field>
        </form>

        <div class="result-list" *ngrxLet="results$ as results">
            <mat-spinner *ngIf="isProcessing$ | ngrxPush; else resultListTemplate" diameter="60" color="accent"></mat-spinner>

            <ng-template #resultListTemplate>
                <ng-container *ngIf="searchInput?.value; else recentSearchesListTemplate">
                    <uav-id-client-lib-geocoding-result
                        *ngFor="let result of results"
                        @resultSlideIn
                        [result]="result"
                        [iconName]="'map-pin'"
                        (resultSelect)="updateUserPosition($event)"
                    ></uav-id-client-lib-geocoding-result>

                    <div *ngIf="results !== undefined && !results.length" @resultSlideIn class="no-results">
                        {{ "uavIdClientLibReport.geocodingSearch.noResultsLabel" | transloco }}
                    </div>
                </ng-container>

                <ng-template #recentSearchesListTemplate>
                    <ng-container *ngrxLet="recentSearches$ as recentSearches">
                        <h3 *ngIf="recentSearches?.length">
                            {{ "uavIdClientLibReport.geocodingSearch.recentSearchesHeaderLabel" | transloco }}
                        </h3>

                        <uav-id-client-lib-geocoding-result
                            *ngFor="let recentSearch of recentSearches"
                            @resultSlideIn
                            [result]="recentSearch"
                            [iconName]="'time'"
                            (resultSelect)="lookUpRecentSearch($event)"
                        ></uav-id-client-lib-geocoding-result>
                    </ng-container>
                </ng-template>
            </ng-template>
        </div>
    </div>
</ng-template>
