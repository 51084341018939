import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import {
    CodeVerificationError,
    CodeVerificationErrorType,
    ResetPasswordError,
    ResetPasswordErrorType,
    UpdatePasswordData,
} from "../../authorization.models";
import { AuthorizationActions } from "../../state/authorization.actions";
import { AuthorizationState } from "../../state/authorization.state";

interface ResetPasswordComponentState {
    isOnEmailStep: boolean;
}

const LOGIN_URL = "/login";

@UntilDestroy()
@Component({
    templateUrl: "reset-password.component.html",
    styleUrls: ["../authorization.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ResetPasswordComponent {
    protected readonly isOnEmailStep$ = this.localStore.selectByKey("isOnEmailStep");
    protected readonly isProcessing$ = this.store.select(AuthorizationState.isUpdatePasswordProcessing);

    protected readonly resetPasswordForm = new FormGroup({
        email: new FormControl<string>("", { validators: [Validators.required, Validators.email], nonNullable: true }),
    });

    constructor(
        private readonly localStore: LocalComponentStore<ResetPasswordComponentState>,
        private readonly router: Router,
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            isOnEmailStep: true,
        });
    }

    protected goToLoginPage(): void {
        this.localStore.patchState({ isOnEmailStep: true });
        this.router.navigateByUrl(LOGIN_URL);
    }

    protected sendVerificationCode(): void {
        this.resetPasswordForm.markAllAsTouched();

        if (this.resetPasswordForm.invalid) {
            return;
        }

        this.store
            .dispatch(new AuthorizationActions.ResetPassword(this.resetPasswordForm.getRawValue().email))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.resetPasswordError);

                if (error) {
                    this.handleResetPasswordError(error);

                    return;
                }

                this.localStore.patchState({ isOnEmailStep: false });
            });
    }

    protected updatePassword(data: UpdatePasswordData): void {
        this.store
            .dispatch(new AuthorizationActions.UpdatePassword(data))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.updatePasswordVerificationError);

                if (error) {
                    this.handleVerificationError(error);

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("uavIdClientLibAuth.resetPassword.updatePasswordSuccessMessage")
                );
                this.localStore.patchState({ isOnEmailStep: true });
                this.router.navigateByUrl(LOGIN_URL);
            });
    }

    protected resendVerificationCode(): void {
        this.store
            .dispatch(new AuthorizationActions.ResendUpdatePasswordVerificationCode())
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(AuthorizationState.updatePasswordVerificationError);

                if (error) {
                    this.handleResendVerificationError(error);

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("uavIdClientLibAuth.resetPassword.resendVerificationCodeSuccessMessage")
                );
            });
    }

    private handleResetPasswordError(error: ResetPasswordError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case ResetPasswordErrorType.AccountNotFound:
                errorMessageKey = "uavIdClientLibAuth.resetPassword.accountDoesntExistError";
                break;
            default:
                errorMessageKey = "uavIdClientLibAuth.resetPassword.unknownError";
        }

        this.toastrService.error(this.translocoService.translate(errorMessageKey));
    }

    private handleVerificationError(error: CodeVerificationError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case CodeVerificationErrorType.InvalidCode: {
                errorMessageKey = "uavIdClientLibAuth.resetPassword.invalidCodeError";
                break;
            }
            case CodeVerificationErrorType.CodeExpired: {
                errorMessageKey = "uavIdClientLibAuth.resetPassword.codeExpiredError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibAuth.resetPassword.codeVerificationUnknownError";
            }
        }

        this.toastrService.error(this.translocoService.translate(errorMessageKey));
    }

    private handleResendVerificationError(error: CodeVerificationError): void {
        let errorMessageKey: string;

        switch (error.type) {
            case CodeVerificationErrorType.CodeExpired: {
                errorMessageKey = "uavIdClientLibAuth.resetPassword.codeExpiredError";
                break;
            }
            default: {
                errorMessageKey = "uavIdClientLibAuth.resetPassword.codeResendFailedError";
            }
        }

        this.toastrService.error(this.translocoService.translate(errorMessageKey));
    }
}
