import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { DeviceSize, DeviceSizeService } from "@dtm-frontend/shared/ui";
import { ReportState } from "@dtm-frontend/uav-identification-client-lib/report";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";
import { Store } from "@ngxs/store";
import { combineLatestWith } from "rxjs";
import { map } from "rxjs/operators";

const USER_PROFILE_PATH = "/user-profile";

@Component({
    selector: "uav-id-client-header",
    templateUrl: "header.component.html",
    styleUrls: ["header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    protected readonly isAdmin$ = this.store.select(AuthState.roles).pipe(map((roles) => roles?.includes(UavIdRole.Admin)));
    protected readonly userPosition$ = this.store.select(ReportState.userPosition);
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);
    protected readonly userName$ = this.store.select(AuthState.userFirstName).pipe(
        combineLatestWith(this.store.select(AuthState.userLastName)),
        map(([firstName, lastName]) => `${firstName} ${lastName}`)
    );
    protected readonly isHeaderUserButtonShortMode$ = this.deviceSizeService.getSizeObservable(
        DeviceSize.Smartphone,
        DeviceSize.SmartphoneWide
    );

    constructor(private readonly deviceSizeService: DeviceSizeService, private readonly router: Router, private readonly store: Store) {}

    protected login(): void {
        this.store.dispatch(new AuthActions.GoToLoginPage(this.router.routerState.snapshot.url));
    }

    protected logout(): void {
        this.store.dispatch(new AuthActions.Logout());
    }

    protected goToUserProfile(): void {
        this.router.navigateByUrl(USER_PROFILE_PATH);
    }
}
