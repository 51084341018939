import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { ReportFormData } from "../models/report.model";

export namespace ReportActions {
    export class SendReport {
        public static readonly type = "[Report] Send report";
        constructor(public reportForm: ReportFormData) {}
    }

    export class UpdateUserPosition {
        public static readonly type = "[Report] Update user position";
        constructor(public latitude: number, public longitude: number, public accuracy?: number) {}
    }

    export class VerifyIdentity {
        public static readonly type = "[Report] Verify identity";
        constructor(public phoneNumber: PhoneNumber) {}
    }

    export class SendIdentityVerificationCode {
        public static readonly type = "[Report] Send identity verification code";
        constructor(public verificationId: string, public code: string) {}
    }

    export class ResendIdentityVerificationCode {
        public static readonly type = "[Report] Resend identity verification code";
        constructor(public verificationId: string) {}
    }

    export class ClearIdentity {
        public static readonly type = "[Report] Clear identity";
    }
}
