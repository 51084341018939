import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Network } from "@capacitor/network";
import { TranslocoService } from "@jsverse/transloco";
import { ToastrService } from "ngx-toastr";
import { EMPTY, from, switchMap } from "rxjs";

@Injectable()
export class UavIdentificationClientInterceptor implements HttpInterceptor {
    constructor(private readonly toastrService: ToastrService, private readonly translocoService: TranslocoService) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler) {
        return from(Network.getStatus()).pipe(
            switchMap(({ connected }) => {
                if (!connected) {
                    this.toastrService.error(this.translocoService.translate("uavIdClientLibShared.interceptor.offlineMessage"));

                    return EMPTY;
                }

                return next.handle(request);
            })
        );
    }
}
