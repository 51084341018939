import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { CodeVerificationTemplateComponent } from "../../../../shared/components/code-verification-template/code-verification-template.component";

@Component({
    selector: "uav-id-client-lib-report-step-verification-code",
    templateUrl: "./report-step-verification-code.component.html",
    styleUrls: ["../report-steps-shared.component.scss", "report-step-verification-code.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportStepVerificationCodeComponent {
    @ViewChild(CodeVerificationTemplateComponent) public codeVerificationTemplateComponent!: CodeVerificationTemplateComponent;

    @Output() public next = new EventEmitter<string>();
    @Output() public resendCode = new EventEmitter();
    @Output() public previous = new EventEmitter<void>();

    public resendSmsCode() {
        this.resendCode.emit();
    }

    public verifySmsCode(code: string) {
        this.next.emit(code);
    }

    public resetTimer(): void {
        this.codeVerificationTemplateComponent.triggerResendCodeTimer();
    }
}
