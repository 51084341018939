import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DTM_AUTH_GUARD_REQUIRED_ROLES } from "@dtm-frontend/shared/auth";
import { AuthorizationRoutingModule } from "@dtm-frontend/uav-identification-client-lib/authorization";
import { HelpRoutingModule } from "@dtm-frontend/uav-identification-client-lib/help";
import { MyInterventionsRoutingModule } from "@dtm-frontend/uav-identification-client-lib/my-interventions";
import { MyReportsRoutingModule } from "@dtm-frontend/uav-identification-client-lib/my-reports";
import { ReportRoutingModule } from "@dtm-frontend/uav-identification-client-lib/report";
import { UserProfileRoutingModule } from "@dtm-frontend/uav-identification-client-lib/user-profile";
import { UavIdRole } from "@dtm-frontend/uav-identification-shared-lib/shared";

const routes: Routes = [
    {
        path: "**",
        redirectTo: "report/user-geolocation",
    },
];

@NgModule({
    imports: [
        AuthorizationRoutingModule,
        HelpRoutingModule,
        MyInterventionsRoutingModule,
        MyReportsRoutingModule,
        ReportRoutingModule,
        RouterModule.forRoot(routes),
        UserProfileRoutingModule,
    ],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: [UavIdRole.Civilian, UavIdRole.Officer, UavIdRole.DutyOfficer],
        },
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
